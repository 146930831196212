/**
 * Provides further control over a subscribed store
 */
export class StoreHandle<T> {
  constructor(private _id: number, private _store: Store<T>) {}

  /**
   * Unsubscribe from the store
   */
  public unsubscribe() {
    this._store._unsubscribe(this._id);
  }

  /**
   * Update store state
   * @param callbackFn Callback function allowed to modify the store data, or to return a replacement data instead
   */
  public update(callbackFn: (data: T | undefined) => T | undefined | void) {
    this._store.update(callbackFn, this._id);
  }
}

/**
 * A store implementation, implements a centralized mechanism for exchanging state and (subscribing to) state changes
 */
export class Store<T> {
  protected _data: T | undefined = undefined;

  private _listenerUniqueId = 0;
  protected _listeners: { [key: number]: (data: T | undefined) => void } = {};

  /**
   * Gets current store data
   */
  public get data(): T | undefined {
    // Get data
    return this._data;
  }

  /**
   * Constructor
   * @param data Initial store data
   */
  constructor(data?: T) {
    this._data = data;
  }

  /**
   * Subscribes to store changes
   * @param callbackFn Function called every time store data is updated
   * @param triggerOnSubscribed If true, will trigger the callback function right away
   * @returns Handle used to control the subscribed to store
   */
  public subscribe(callbackFn: (data: T | undefined) => void, { triggerOnSubscribed = false } = {}): StoreHandle<T> {
    // Register subscribed callback
    const id = this._listenerUniqueId++;
    this._listeners[id] = callbackFn;
    // Trigger on subscribe, unless opted out
    if (triggerOnSubscribed) {
      callbackFn(this._data);
    }
    // Return store handle
    return new StoreHandle<T>(id, this);
  }

  /**
   * Unsubscribe from store
   * @param id Unique subscription id
   */
  public _unsubscribe(id: number) {
    delete this._listeners[id];
  }

  /**
   * Update store state
   * @param callbackFn Callback function allowed to modify the store data, or to return a replacement data instead
   * @param id Optional unique subscription id, if present will avoid triggering subscribed callback with the given id
   */
  public update(callbackFn: (data: T | undefined) => T | undefined | void, id?: number) {
    // Update data
    const data = callbackFn(this._data);
    if (data !== undefined) {
      this._data = data;
    }
    // Notify subscribers of changes
    for (const listenerId of Object.keys(this._listeners)) {
      if (listenerId !== id?.toString()) {
        this._listeners[parseInt(listenerId)](this._data);
      }
    }
  }
}

/**
 * Singleton store, store exposing an additional singleton factory method
 */
export class SingletonStore<T> extends Store<T> {
  private static _singleton?: SingletonStore<any>;

  /**
   * Gets a singleton instance of the store
   * @returns Singleton instance of the store
   */
  public static getSingletonInstance<T>(): SingletonStore<T> {
    return this._singleton || (this._singleton = new SingletonStore<T>());
  }
}
