// Import dependencies
import { WidgetConfiguration, SettingsConfigurationFontSizeBehaviors, SettingsConfigurationLineHeightBehaviors } from '../../../../common/types';

/**
 * Provides configuration related functionality
 */
export class ConfigurationService {
  /**
   * Holds singleton instance of the service
   */
  private static _service?: ConfigurationService;
  /**
   * Gets singleton instance of the service
   * @returns Singleton instance of the service
   */
  public static singleton() {
    return ConfigurationService._service || (ConfigurationService._service = new ConfigurationService());
  }

  /**
   * Holds loaded configuration
   */
  private _config = defaultConfiguration;
  /**
   * Gets loaded configuration
   */
  public get config() {
    return this._config;
  }

  /**
   * Initializes configuration service by loading configuration
   * @param Host script element
   */
  public async initialize(scriptEl: HTMLOrSVGScriptElement) {
    // Get configuration path
    const url = scriptEl?.getAttribute('client-url');
    this._config = defaultConfiguration;

    if (url) {
      const loadedConfig = await (await fetch(url)).json();
      this._config = deepMerge(this._config, loadedConfig);
    }
    if (scriptEl.innerHTML.trim()) {
      let content = scriptEl.innerHTML;
      // Try Base64 decoding
      try {
        content = Buffer.from(scriptEl.innerHTML, 'base64').toString();
      } catch {}
      // Try JSON parsing
      try {
        this._config = deepMerge(this._config, JSON.parse(content));
      } catch {}
    }
  }
}

export const defaultConfiguration: WidgetConfiguration = {
  host: '',
  lang: {
    hr: {
      fontFamily: 'OmoType font',
      fontSize: 'Veličina slova',
      lineHeight: 'Prored teksta',
      fontWeight: 'Debljina slova',
      lineSpacing: 'Slovni razmak',
      colors: 'Boja pozadine',
      linkHighlight: 'Naglašeni linkovi',
      power: 'Uključi/isključi',
    },
    en: {
      fontFamily: 'OmoType font',
      fontSize: 'Font size',
      lineHeight: 'Line height',
      fontWeight: 'Font weight',
      lineSpacing: 'Letter spacing',
      colors: 'Background colors',
      linkHighlight: 'Highlight links',
      power: 'On/off',
    },
  },
  stylesheets: {
    analysis: [],
    exceptions: [
      {
        selectors: ['header', 'footer'],
        disable: {
          fontFamily: 'initial',
          fontSize: 'initial',
          lineHeight: 'initial',
          fontWeight: 'initial',
          letterSpacing: 'initial',
          wordSpacing: 'initial',
        },
      },
      {
        selectors: ['i.fa', "i[class^='icon-']", "i[class*=' icon-']"],
        disable: {
          fontFamily: true,
        },
      },
    ],
  },
  presentation: {
    close: {
      showButton: false,
      showMessage: false,
      messageContent: '',
    },
    buttonsPerRow: 3,
    tabIndex: 1,
    zIndex: 2147483647,
    position: {
      x: 'right',
      y: 'bottom',
    },
    size: '60px',
    offset: {
      horizontal: '20px',
      vertical: '20px',
    },
    colors: {
      backgroundButton: '#0b172a',
      foregroundButton: '#627c85',
      highlightedButton: '#ef6c33',
      background: '#0b172a',
      powerButton: '#ef6c33',
      fillColor: 'white',
    },
  },
  settings: {
    globalEnable: {
      enable: true,
    },
    fontFamily: {
      enable: true,
      value: {
        initial: { abbreviation: '', name: '', value: '' },
        values: [
          {
            abbreviation: 'OB',
            name: 'OmoType B',
            value: 'OmoTypeB',
            lineHeightMultiplier: 1,
          },
          {
            abbreviation: 'OD',
            name: 'OmoType D',
            value: 'OmoTypeD',
            lineHeightMultiplier: 1.2,
          },
          {
            abbreviation: 'CS',
            name: 'OTCarta Sans Ext',
            value: 'OTCartaSExt',
            lineHeightMultiplier: 1.1,
          },
        ],
      },
    },
    fontSize: {
      enable: true,
      behavior: SettingsConfigurationFontSizeBehaviors.LINEAR_SCALING,
      value: {
        initial: 1,
        min: 1,
        max: 5,
        step: 0.1,
      },
    },
    fontWeight: {
      enable: true,
      value: {
        initial: {
          name: 'normal',
          value: 400,
        },
        values: [
          { name: 'normal', value: 400 },
          { name: 'OmoBold', value: 500 },
          { name: 'OmoBolder', value: 600 },
          { name: 'OmoBoldest', value: 800 },
        ],
      },
    },
    lineHeight: {
      enable: true,
      behavior: SettingsConfigurationLineHeightBehaviors.LINEAR_SCALING_WITH_FONTSIZE,
      value: {
        initial: 1,
        min: 1,
        max: 4,
        step: 0.2,
      },
    },
    lineSpacing: {
      enable: true,
      value: {
        initial: 0,
        min: 0,
        max: 4,
        step: 0.01,
      },
    },
    colors: {
      enable: true,
      value: {
        initial: { name: '', background: '', foreground: '' },
        values: [
          { name: 'Black', background: 'black', foreground: 'white' },
          { name: 'Aqua', background: '#B0DED5', foreground: 'black' },
          { name: 'White', background: '#EFF3EF', foreground: 'black' },
          { name: 'Nude', background: '#FCD5B6', foreground: 'black' },
          { name: 'Yellow', background: '#F3EE8C', foreground: 'black' },
          {
            name: 'Grey',
            background: 'white',
            foreground: 'black',
            filter: 'grayscale(100%)',
            icon: 'https://api.omoguru.com/omoguruwidget/icons/filter.svg',
          },
        ],
      },
    },
    linkHighlight: {
      enable: true,
    },
  },
  resources: {
    cssFontFaces: 'https://api.omoguru.com/fonts/styles/fonts.css',
  },
};

export const initialConfig = deepMerge(defaultConfiguration, {
  stylesheets: {
    additions: [],
  },
});

/**
 * Deep merge of JSON configurations
 * @param base Target JSON configuration
 * @param updates Sources JSON configurations
 */
function deepMerge(base: { [key: string]: any }, update: { [key: string]: any }): any {
  if (Object.keys(update).length === 0) return base;
  // Compose all keys
  const keys = Object.keys(
    [...Object.keys(base), ...Object.keys(update)].reduce((unique: Record<string, string>, key: string) => {
      unique[key] = key;
      return unique;
    }, {}),
  );
  for (let key of keys) {
    // If both are object types, keep merging
    if (base[key] instanceof Object && !(base[key] instanceof Array) && update[key] instanceof Object && !(update[key] instanceof Array)) {
      deepMerge(base[key], update[key]);
    }
    // ... else overwrite from update
    else if (update.hasOwnProperty(key)) {
      base[key] = update[key];
    }
  }
  return base;
}
