import { composeCssSelector, removeClassByPrefix } from '../..';
import {
  FontFamilySetting,
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
} from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { ControllerService } from '../../ControllerService';
import { BaseAction } from '../BaseAction';

/**
 * Font family action implementation
 */
class FontFamilyAction extends BaseAction<FontFamilySetting> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['fontFamily'];

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {
    'font-family': 'initial',
  };

  public className: string[] = ['__omowidget__fontfamily__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.fontFamily.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return this.initialValue.value !== this.currentValue?.value;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): FontFamilySetting {
    return settings.fontFamily.value.initial;
  }

  public _getCurrentValue(settings: WidgetUserSettings): FontFamilySetting | undefined {
    return settings.fontFamily;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: FontFamilySetting): void {
    settings.fontFamily = value;
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.fontFamily = this.initialValue;
  }
  // #endregion

  // #region Generate action's value as CSS variable
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return [
      globallyEnabled && this.currentValue?.value && this.enabled
        ? `--omowidget--settings--fontfamily--value: ${this.currentValue?.value};`
        : '/* --omowidget--settings--fontfamily--value: */',
      globallyEnabled && this.currentValue?.fontSizeFactor && this.enabled
        ? `--omowidget--settings--fontsize--multiplier: ${this.currentValue?.fontSizeFactor};`
        : '--omowidget--settings--fontsize--multiplier: 1;',
      globallyEnabled && this.currentValue?.lineHeightFactor && this.enabled
        ? `--omowidget--settings--lineheight--multiplier: ${this.currentValue?.lineHeightFactor};`
        : '--omowidget--settings--lineheight--multiplier: 1;',
      globallyEnabled && this.currentValue?.letterSpacingFactor && this.enabled
        ? `--omowidget--settings--letterspacing--multiplier: ${this.currentValue?.letterSpacingFactor};`
        : '--omowidget--settings--letterspacing--multiplier: 1;',
      globallyEnabled && this.currentValue?.wordSpacingFactor && this.enabled
        ? `--omowidget--settings--wordspacing--multiplier: ${this.currentValue?.wordSpacingFactor};`
        : '--omowidget--settings--wordspacing--multiplier: 1;',
    ].join('\n');
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    if (exception.disable.fontFamily) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontfamily__')];
    }
    return ['__omo__noelement__'];
  }

  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && exception.disable.fontFamily) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'font-family',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, exception);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateException(exception: WidgetConfigurationStylingException): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'fontFamily', exception.disable.fontFamily),
    );
  }
  // #endregion

  // #region Generate action's additions' declarations
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    if (addition.enable.fontFamily) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontfamily__')];
    }
    return ['__omo__noelement__'];
  }

  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && addition.enable.fontFamily) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'font-family',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, addition);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateAddition(addition: WidgetConfigurationStylingAddition): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'fontFamily', addition.enable.fontFamily),
    );
  }
  // #endregion

  // #region Override CSS declaration
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--fontfamily--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--fontfamily--value: initial;`);
      override.variables.push(`--omowidget--settings--lineheight--multiplier: 1;`);
      override.variables.push(`--omowidget--settings--fontsize--multiplier: 1;`);
      override.variables.push(`--omowidget--settings--letterspacing--multiplier: 1;`);
      override.variables.push(`--omowidget--settings--wordspacing--multiplier: 1;`);
    }
    // Set CSS property value (Set user setting, or fallback to original CSS declaration or closest parent's original CSS declaration)
    override.variables.push(
      `--omowidget--css--fontfamily--calculated--value: var(--omowidget--settings--fontfamily--value, var(--omowidget--css--fontfamily--original--value));`,
    );

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--fontfamily--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Explicit value exception
      if (typeof exception?.disable?.fontFamily === 'string') {
        override.declarations.push(`${declaration.name}: ${exception?.disable.fontFamily as string} ${importantSyntax};`);
      }
      // Explicit value addition
      if (typeof addition?.enable?.fontFamily === 'string') {
        override.variables.push(`--omowidget--css--fontfamily--original--value: ${addition?.enable?.fontFamily};`);
        override.declarations.push(`${declaration.name}: var(--omowidget--css--fontfamily--calculated--value) ${importantSyntax};`);
      }
      // Non-explicit value addition
      else if (addition?.enable?.fontFamily) {
        override.declarations.push(`${declaration.name}: var(--omowidget--css--fontfamily--calculated--value) ${importantSyntax};`);
      }
    }

    // Return overrides
    return override;
  }
  // #endregion

  // #region Reflect state onto root element
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return `html.__omowidget__fontfamily__ [style*="font:"], html.__omowidget__fontfamily__ [style*="font-family:"] {\n${[
      this.overrideCssStyleDeclaration({ name: 'font-family', value: 'initial !important' }),
    ].join('\n')}\n}`;
  }

  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (this.currentValue?.value !== this.initialValue.value) {
        removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__fontfamily__');
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__fontfamily__`);
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__fontfamily__${this.currentValue?.name?.replace(/\s/g, '_')}`);
      } else {
        removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__fontfamily__');
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-fontfamily', this.currentValue?.value || '');
    } else {
      removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__fontfamily__');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-fontfamily');
    }
  }
  // #endregion
}

// Export
export const fontFamilyAction = new FontFamilyAction();
