// Import dependencies
import { render, h } from 'preact';
import { WidgetUserSettings } from '../../../../common/types';
import { Widget } from '../../components';
import { ConfigurationService } from '../ConfigurationService';
import { SettingService } from '../SettingService';
import { StyleOverrideService } from '../StyleOverrideService';
import { ControllerService } from '../ControllerService';
import { UserSettingsStore } from '../../stores';

// Get parent script element
const scriptEl: HTMLOrSVGScriptElement | null = document.currentScript;

/**
 * Widget bootstrapping service
 */
export class BootstrapService {
  /**
   * Holds widget's bootstrap status
   */
  static _bootstrapped = false;

  /**
   * Initializes the widget into the host page
   */
  static async initialize() {
    // Check parent script element
    if (!scriptEl) {
      throw new Error('OMOGURU WIDGET: Parent script element unaccessible - initialization failed!');
    }

    // Initialize user settings from default configuration
    UserSettingsStore.initialize(true);

    // Expose widget controller to global object
    (window as any).__omowidget__ = ControllerService.singleton(scriptEl);
  }

  /**
   * Bootstraps the widget onto the host page, rendering and displaying it's UI and injecting it's overriding styles
   */
  static async bootstrap() {
    // Check parent script element
    if (!scriptEl) {
      throw new Error('OMOGURU WIDGET: Parent script element unaccessible - initialization failed!');
    }

    // Check if already bootstrapped
    if (BootstrapService._bootstrapped) {
      return;
    }

    // Check if widget enabled
    if (!!UserSettingsStore.getSingletonInstance<WidgetUserSettings>().data?.closed.value) {
      return;
    }
    BootstrapService._bootstrapped = true;

    // Load configuration
    const config = ConfigurationService.singleton();
    await config.initialize(scriptEl);

    // Initialize user settings from now loaded configuration (skip subscription - already subscribed)
    UserSettingsStore.initialize(false);

    // Initialize style overriding on parent page
    await StyleOverrideService.singleton().initialize(scriptEl);
    UserSettingsStore.getSingletonInstance<WidgetUserSettings>().subscribe(
      async settings => {
        if (settings) {
          await StyleOverrideService.singleton().update(settings);
        }
      },
      { triggerOnSubscribed: true },
    );

    // Update html class list based on user settings
    SettingService.updateHtmlClassList(UserSettingsStore.getSingletonInstance<WidgetUserSettings>().data);
    // Subscribe to user settings change
    SettingService.subscribeToWidgetSettingsChange();

    // Bootstrap into host page
    if (scriptEl) {
      const hostEl = document.createElement('div');
      hostEl.setAttribute('__omowidget__host__', '');
      document.body.appendChild(hostEl);
      render(<Widget />, hostEl);
    }
  }
}
