import { composeCssSelector } from '../..';
import {
  SettingsConfigurationFontSizeBehaviors,
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
} from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { ControllerService } from '../../ControllerService';
import { BaseAction } from '../BaseAction';
import { fontFamilyAction } from '../FontFamily';

/**
 * Font size action implementation
 */
class FontSizeAction extends BaseAction<number> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['fontSize'];
  public get config() {
    return ConfigurationService.singleton().config.settings.fontSize;
  }

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {
    'font-size': (el: Element) => window.getComputedStyle(el, null).fontSize,
  };

  public className: string[] = ['__omowidget__fontsize__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.fontSize.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    if (this.config.behavior === SettingsConfigurationFontSizeBehaviors.MIN_VALUE || this.currentValue !== this.initialValue) {
      if (this.currentValue !== this.initialValue) {
        return true;
      }
    }
    return false;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): number {
    return settings.fontSize.value.initial;
  }

  public _getCurrentValue(settings: WidgetUserSettings): number | undefined {
    return settings.fontSize.value;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean): void {
    settings.fontSize.value = this._setMinMaxValue(
      (settings.fontSize.value || this.initialValue) + (value ? +1 : -1) * this.config.value.step,
      this.config.value.min,
      this.config.value.max,
    );
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.fontSize.value = this.initialValue;
  }
  // #endregion

  // #region Generate action's value as CSS variable
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return globallyEnabled && this.currentValue && this.enabled
      ? `--omowidget--settings--fontsize--value: ${
          this.config.behavior === SettingsConfigurationFontSizeBehaviors.LINEAR_SCALING ? fontSizeAction.currentValue : `${fontSizeAction.currentValue}px`
        };`
      : '/* --omowidget--settings--fontsize--value = undefined */';
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    if (exception.disable.fontSize) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontsize__')];
    }
    return ['__omo__noelement__'];
  }

  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && exception.disable.fontSize) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'font-size',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, exception);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateException(exception: WidgetConfigurationStylingException): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'fontSize', exception.disable.fontSize),
    );
  }
  // #endregion

  // #region Generate action's additions' declarations
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    if (addition.enable.fontSize) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontsize__')];
    }
    return ['__omo__noelement__'];
  }

  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && addition.enable.fontSize) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'font-size',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, addition);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateAddition(addition: WidgetConfigurationStylingAddition): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'fontSize', addition.enable.fontSize),
    );
  }
  // #endregion

  // #region Override CSS declaration
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if important
    const fontFamilyActionEnabled =
      exceptionOrAdditionConfiguration === undefined || (exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition)?.enable?.fontFamily;
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--fontsize--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--fontsize--value: initial;`);
      override.variables.push(`--omowidget--settings--fontsize--multiplier: 1;`);
    }

    // Check if declaration value is scalable
    if (declaration.value === undefined || !isNaN(parseFloat(declaration.value))) {
      // Scale all font-sizes linearly
      if (this.config.behavior === SettingsConfigurationFontSizeBehaviors.LINEAR_SCALING) {
        // Compose full CSS value (with units) from user setting and limit the composed value to only be larger than the original value
        // (or invalid variable if no setting)
        const factors = [
          'var(--omowidget--css--fontsize--original--value)',
          'var(--omowidget--settings--fontsize--value, 1)',
          fontFamilyActionEnabled ? 'var(--omowidget--settings--fontsize--multiplier, 1)' : '1',
        ];
        const calculatedSyntax = `calc(${factors.join(' * ')})`;
        const maxLimitSyntax =
          declaration.value === undefined ? this.config.limits?.max : `max(var(--omowidget--css--fontsize--original--value), ${this.config.limits?.max})`;
        const minLimitSyntax =
          declaration.value === undefined ? this.config.limits?.min : `min(var(--omowidget--css--fontsize--original--value), ${this.config.limits?.min})`;
        if (!this.config.limits?.min && !this.config.limits?.max) {
          // Compose unlimited declaration
          override.variables.push(`--omowidget--css--fontsize--calculated--value: ${calculatedSyntax};`);
        } else if (this.config.limits?.min && !this.config.limits?.max) {
          // Compose min-limited declaration
          override.variables.push(`--omowidget--css--fontsize--calculated--value: max(${minLimitSyntax}, ${calculatedSyntax});`);
        } else if (this.config.limits?.min && this.config.limits?.max) {
          // Compose max-limited declaration
          override.variables.push(`--omowidget--css--fontsize--calculated--value: min(${maxLimitSyntax}, ${calculatedSyntax});`);
        } else if (this.config.limits?.min && this.config.limits?.max) {
          // Compose min&max-limited declaration
          override.variables.push(`--omowidget--css--fontsize--calculated--value: max(${minLimitSyntax}, min(${maxLimitSyntax}, ${calculatedSyntax}));`);
        }
      }

      // Manipulate min font-size value
      else if (this.config.behavior === SettingsConfigurationFontSizeBehaviors.MIN_VALUE) {
        // Compose full CSS value (with units) from user setting and limit the composed value to only be larger than the original value
        // (or invalid variable if no setting)
        const calculatedFallbackSyntax = `var(--omowidget--settings--fontsize--value, var(--omowidget--css--fontsize--original--value))`;
        const calculatedSyntax =
          declaration.value === undefined ? calculatedFallbackSyntax : `max(var(--omowidget--css--fontsize--original--value), ${calculatedFallbackSyntax})`;
        override.variables.push(`--omowidget--css--fontsize--calculated--value: ${calculatedSyntax};`);
      }
    }

    // If original CSS value is not scalable leave as is
    else {
      override.variables.push(`/* Font-Size: Unscalable: */`);
      override.variables.push(`--omowidget--css--fontsize--calculated--value: var(--omowidget--css--fontsize--original--value);`);
    }

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--fontsize--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Explicit value exception
      if (typeof exception?.disable?.fontSize === 'string') {
        override.declarations.push(`${declaration.name}: ${exception?.disable.fontSize as string} ${importantSyntax};`);
      }
      // Explicit value addition
      if (typeof addition?.enable?.fontSize === 'string') {
        override.variables.push(`--omowidget--css--fontsize--original--value: ${addition?.enable?.fontSize};`);
        override.declarations.push(`${declaration.name}: var(--omowidget--css--fontsize--calculated--value) ${importantSyntax};`);
      }
      // Non-explicit value addition
      else if (addition?.enable?.fontSize) {
        override.declarations.push(`${declaration.name}: var(--omowidget--css--fontsize--calculated--value) ${importantSyntax};`);
      }
    }

    // Return overrides
    return override;
  }
  // #endregion

  // #region Reflect state onto root element
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return `html.__omowidget__fontsize__ [style*="font:"], html.__omowidget__fontsize__ [style*="font-size:"] {\n${[
      this.overrideCssStyleDeclaration({
        name: 'font-size',
        value: '1em !important',
      }),
    ].join('\n')}\n}`;
  }

  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (
        this.config.behavior === SettingsConfigurationFontSizeBehaviors.MIN_VALUE ||
        this.active ||
        (fontFamilyAction.currentValue?.fontSizeFactor !== undefined && fontFamilyAction.currentValue?.fontSizeFactor !== 1)
      ) {
        if (this.active) {
          document.getElementsByTagName('html')[0].classList.add('__omowidget__fontsize__');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__fontsize__');
        }
      } else {
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__fontsize__');
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-fontsize', this.currentValue?.toString() || '');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__fontsize__');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-fontsize');
    }
  }
  // #endregion

  /**
   * Checks if localStorage setting behavior differs from configuration setting behavior and if does, localStorage value falls back to initial configuration value
   * @param localStorageSettings User settings in local storage
   */
  public checkSettingValuePerBehavior(localStorageSettings: any) {
    if (localStorageSettings) {
      if (this.config.behavior !== localStorageSettings.fontSize.behavior) {
        localStorageSettings.fontSize.value = this.initialValue;
        localStorageSettings.fontSize.behavior = this.config.behavior;
      }
    }
    return localStorageSettings;
  }
}

// Export
export const fontSizeAction = new FontSizeAction();
