/**
 * Enumerates all actions' keys
 */
export type ActionKey =
  | 'globalEnabled'
  | 'fontFamily'
  | 'fontSize'
  | 'lineHeight'
  | 'fontWeight'
  | 'lineSpacing'
  | 'wordSpacing'
  | 'letterSpacing'
  | 'colors'
  | 'fgColors'
  | 'bgColors'
  | 'linkHighlight'
  | 'power';

/**
 * OMO Widget configuration type definition
 */
export type WidgetConfiguration = {
  host: string;
  lang: LanguageConfiguration;
  stylesheets: {
    analysis: WidgetConfigurationStylesheets[];
    exceptions?: WidgetConfigurationStylingException[];
    additions?: WidgetConfigurationStylingAddition[];
    lockRemValues?: boolean;
  };
  presentation: UIConfiguration;
  settings: SettingsConfiguration;
  resources: ResourcesConfiguration;
};

/**
 * Language configuration
 */
export type LanguageConfiguration = Record<string, TooltipConfiguration>;

/**
 * Tooltip configuration
 */
export type TooltipConfiguration = Partial<Record<ActionKey, string>>;

/**
 * OMO Widget configuration, Crawled stylesheets' info
 */
export type WidgetConfigurationStylesheets = {
  queries: WidgetConfigurationStylesheetsMediaQuery[];
  remUsages: WidgetConfigurationStylesheetsGlobalDeclaration[];
};

/**
 * OMO Widget configuration, styling exceptions (selectors not to have their style overridden)
 */
export type WidgetConfigurationStylingException = {
  selectors: string[];
  disable: Partial<Record<ActionKey, boolean | string>>;
};

/**
 * OMO Widget configuration, styling additions (selectors not in host page's CSS, but that should be added)
 */
export type WidgetConfigurationStylingAddition = {
  selectors: string[];
  enable: Partial<Record<ActionKey, boolean | string>>;
};

/**
 * OMO Widget configuration, Crawled stylesheets' media query info
 */
export type WidgetConfigurationStylesheetsMediaQuery = {
  query?: string;
  _source?: string;
  _timestamp?: Date;
  rules: WidgetConfigurationStylesheetsSelector[];
};

/**
 * OMO Widget configuration, Crawled stylesheets' selector info
 */
export type WidgetConfigurationStylesheetsSelector = {
  selectors: string[];
  declarations: WidgetConfigurationStylesheetsDeclaration[];
};

/**
 * OMO Widget configuration, Crawled stylesheets' declaration info
 */
export type WidgetConfigurationStylesheetsDeclaration = {
  name: string;
  value?: string;
};
export type WidgetConfigurationStylesheetsGlobalDeclaration = {
  media?: string;
  selectors: string[];
  name: string;
  value?: string;
};

/**
 * Widget closing configuration
 */
export type CloseWidgetConfiguration = {
  showButton: boolean;
  showMessage: boolean;
  messageContent?: string;
};
/**
 * OMO Widget UI configuration type definition
 */
export type UIConfiguration = {
  close: CloseWidgetConfiguration;
  tabIndex: number;
  zIndex: number;
  buttonsPerRow: number;
  position: {
    x: 'left' | 'right';
    y: 'top' | 'bottom';
  };
  size: string;
  offset: {
    horizontal: string;
    vertical: string;
  };
  colors: {
    backgroundButton: string;
    foregroundButton: string;
    highlightedButton: string;
    background: string;
    powerButton: string;
    fillColor?: string;
  };
};

/**
 * OMO Widget configuration, user settings
 */
export type SettingsConfiguration = {
  globalEnable: {
    enable: boolean;
  };
  fontSize: {
    enable: boolean;
    behavior: SettingsConfigurationFontSizeBehaviors;
    value: SettingsNumericValueConfiguration;
    limits?: SettingsLimitConfiguration;
  };
  fontFamily: {
    enable: boolean;
    value: {
      initial: SettingsFontFamilyValueConfiguration;
      values: SettingsFontFamilyValueConfiguration[];
    };
  };
  fontWeight: {
    enable: boolean;
    value: {
      initial: SettingsFontWeightValueConfiguration;
      values: SettingsFontWeightValueConfiguration[];
    };
  };
  lineSpacing: {
    enable: boolean;
    value: SettingsNumericValueConfiguration;
  };
  lineHeight: {
    enable: boolean;
    behavior: SettingsConfigurationLineHeightBehaviors;
    value: SettingsNumericValueConfiguration;
  };
  colors: {
    enable: boolean;
    value: {
      initial: SettingsColorValueConfiguration;
      values: SettingsColorValueConfiguration[];
    };
  };
  linkHighlight: {
    enable: boolean;
  };
};

/**
 * Font-size setting behavior enum
 */
export enum SettingsConfigurationFontSizeBehaviors {
  LINEAR_SCALING = 'linear-scaling',
  MIN_VALUE = 'min-value',
}

/**
 * Font-size setting behavior enum
 */
export enum SettingsConfigurationLineHeightBehaviors {
  LINEAR_SCALING = 'linear-scaling',
  LINEAR_SCALING_WITH_FONTSIZE = 'linear-scaling-with-fontsize',
}

/**
 * OMO Widget configuration, user setting numeric value
 */
export type SettingsNumericValueConfiguration = {
  initial: number;
  min?: number;
  max?: number;
  step: number;
};

/**
 * OMO Widget configuration, user setting font-family value
 */
export type SettingsFontFamilyValueConfiguration = {
  abbreviation: string;
  name: string;
  value: string;
  fontSizeMultiplier?: number;
  lineHeightMultiplier?: number;
  letterSpacingMultiplier?: number;
  wordSpacingMultiplier?: number;
};

/**
 * Color filter type configuration
 */
export type SettingsFilterConfiguration = {
  name: string;
  icon: string;
  value: string;
};
/**
 * OMO Color configuration, user setting color value
 */
export type SettingsColorValueConfiguration = { name: string; background?: string; foreground?: string; filter?: string; icon?: string };

/**
 * OMO Widget configuration, font-weight
 */
export type SettingsFontWeightValueConfiguration = {
  name: string;
  value: number;
};

/**
 * OMO Widget configuration, styling limits (minimum/maximum overridden values for a property)
 */
export type SettingsLimitConfiguration = {
  min?: string;
  max?: string;
};

/**
 * OMO Widget configuration, resources
 */
export type ResourcesConfiguration = {
  cssFontFaces?: string;
  injectCssSyntax?: string;
  linkHighlight?: string;
};

/**
 * Exception/Addition validation error description
 */
export type ExceptionOrAdditionValidationErrorDescription = {
  instance: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition;
  message: string;
  key: keyof CSSStyleDeclaration;
  value: string;
  cssValues: Record<string, HTMLElement[]>;
  calculatedCssValues: Record<string, HTMLElement[]>;
};
