import { composeCssSelector, removeClassByPrefix } from '../..';
import {
  ColorsSetting,
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
} from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { ControllerService } from '../../ControllerService';
import { BaseAction } from '../BaseAction';

/**
 * Colors action implementation
 */
class ColorsAction extends BaseAction<ColorsSetting> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['colors', 'fgColors', 'bgColors'];

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {
    color: 'initial',
    'background-color': 'initial',
  };

  public className: string[] = ['__omowidget__background__', '__omowidget__foreground__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.colors.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return (
      this.initialValue.background !== this.currentValue?.background ||
      this.initialValue.foreground !== this.currentValue?.foreground ||
      this.initialValue.name !== this.currentValue?.name ||
      this.initialValue.filter !== this.currentValue?.filter
    );
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): ColorsSetting {
    return settings.colors.value.initial;
  }

  public _getCurrentValue(settings: WidgetUserSettings): ColorsSetting | undefined {
    return settings.colors;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: ColorsSetting): void {
    settings.colors = value;
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.colors = this.initialValue;
  }
  // #endregion

  // #region Generate action's value as CSS variable
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return [
      globallyEnabled && this.currentValue?.background && this.enabled
        ? `--omowidget--settings--background--value: ${this.currentValue?.background};`
        : '/* --omowidget--settings--background--value = undefined */',
      globallyEnabled && this.currentValue?.filter && this.enabled
        ? `--omowidget--settings--filter--value: ${this.currentValue?.filter};`
        : '/* --omowidget--settings--filter--value = undefined */',
      globallyEnabled && this.currentValue?.foreground && this.enabled
        ? `--omowidget--settings--foreground--value: ${this.currentValue?.foreground};`
        : '/* --omowidget--settings--foreground--value = undefined */',
    ].join('\n');
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    if (exception.disable.colors) {
      return [
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__foreground__'),
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__background__'),
      ];
    } else {
      if (exception.disable.fgColors) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__foreground__')];
      }
      if (exception.disable.bgColors) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__background__')];
      }
    }
    return ['__omo__noelement__'];
  }

  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled) {
      const foregroundColorDeclaration = {
        name: 'color',
      };
      const backgroundColorDeclaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'background-color',
      };
      if (exception.disable.colors) {
        const overrideFg = this._overrideCssStyleDeclarationFgColor(foregroundColorDeclaration, exception);
        override.variables.push(...overrideFg.variables);
        override.declarations.push(...overrideFg.declarations);
        const overrideBg = this._overrideCssStyleDeclarationBgColor(backgroundColorDeclaration, exception);
        override.variables.push(...overrideBg.variables);
        override.declarations.push(...overrideBg.declarations);
      } else {
        if (exception.disable.fgColors) {
          const overrideFg = this._overrideCssStyleDeclarationFgColor(foregroundColorDeclaration, exception);
          override.variables.push(...overrideFg.variables);
          override.declarations.push(...overrideFg.declarations);
        }
        if (exception.disable.bgColors) {
          const overrideBg = this._overrideCssStyleDeclarationBgColor(backgroundColorDeclaration, exception);
          override.variables.push(...overrideBg.variables);
          override.declarations.push(...overrideBg.declarations);
        }
      }
    }

    // Return overrides
    return override;
  }

  public validateException(exception: WidgetConfigurationStylingException): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'color', exception.disable.fgColors || exception.disable.colors),
    );
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'backgroundColor', exception.disable.bgColors || exception.disable.colors),
    );
  }
  // #endregion

  // #region Generate action's additions' declarations
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    if (addition.enable.colors) {
      return [
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__foreground__'),
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__background__'),
      ];
    } else {
      if (addition.enable.fgColors) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__background__')];
      }
      if (addition.enable.bgColors) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__foreground__')];
      }
    }
    return ['__omo__noelement__'];
  }

  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled) {
      const foregroundColorDeclaration = {
        name: 'color',
      };
      const backgroundColorDeclaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'background-color',
      };
      if (addition.enable.colors) {
        const overrideFg = this._overrideCssStyleDeclarationFgColor(foregroundColorDeclaration, addition);
        override.variables.push(...overrideFg.variables);
        override.declarations.push(...overrideFg.declarations);
        const overrideBg = this._overrideCssStyleDeclarationBgColor(backgroundColorDeclaration, addition);
        override.variables.push(...overrideBg.variables);
        override.declarations.push(...overrideBg.declarations);
      } else {
        if (addition.enable.fgColors) {
          const overrideFg = this._overrideCssStyleDeclarationFgColor(foregroundColorDeclaration, addition);
          override.variables.push(...overrideFg.variables);
          override.declarations.push(...overrideFg.declarations);
        }
        if (addition.enable.bgColors) {
          const overrideBg = this._overrideCssStyleDeclarationBgColor(backgroundColorDeclaration, addition);
          override.variables.push(...overrideBg.variables);
          override.declarations.push(...overrideBg.declarations);
        }
      }
    }

    // Return overrides
    return override;
  }

  public validateAddition(addition: WidgetConfigurationStylingAddition): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'color', addition.enable.fgColors || addition.enable.colors),
    );

    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'backgroundColor', addition.enable.bgColors || addition.enable.colors),
    );
  }
  // #endregion

  // #region Override CSS declaration
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Process foreground-color overrides
    if (declaration.name === 'color') {
      const overrideFg = this._overrideCssStyleDeclarationFgColor(declaration, exceptionOrAdditionConfiguration);
      override.variables.push(...overrideFg.variables);
      override.declarations.push(...overrideFg.declarations);
    }
    // Process background-color overrides
    else if (declaration.name === 'background-color') {
      const overrideBg = this._overrideCssStyleDeclarationBgColor(declaration, exceptionOrAdditionConfiguration);
      override.variables.push(...overrideBg.variables);
      override.declarations.push(...overrideBg.declarations);
    }

    // Return overrides
    return override;
  }
  private _overrideCssStyleDeclarationFgColor(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--foreground--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--foreground--value: initial;`);
    }
    // Set CSS property value (Set user setting, or fallback to original CSS declaration or closest parent's original CSS declaration)
    if (!declaration.value?.startsWith('rgba') && !declaration.value?.startsWith('hsla') && declaration.value !== 'transparent') {
      override.variables.push(
        `--omowidget--css--foreground--calculated--value: var(--omowidget--settings--foreground--value, var(--omowidget--css--foreground--original--value));`,
      );
    } else {
      override.variables.push(`--omowidget--css--foreground--calculated--value: var(-omowidget--css--foreground--original--value,);`);
    }

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--foreground--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Check specific key "fgColors"
      if (exception?.disable?.fgColors || addition?.enable?.fgColors) {
        // Explicit value exception
        if (typeof exception?.disable?.fgColors === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.fgColors as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.fgColors === 'string') {
          override.variables.push(`--omowidget--css--foreground--original--value: ${addition?.enable?.fgColors};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--foreground--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (addition?.enable?.fgColors) {
          // Set calculated value
          override.declarations.push(`${declaration.name}: var(--omowidget--css--foreground--calculated--value) ${importantSyntax};`);
        }
      }
      // Check generic key "colors"
      else if (exception?.disable?.colors || addition?.enable?.colors) {
        // Explicit value exception
        if (typeof exception?.disable?.colors === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.colors as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.colors === 'string') {
          override.variables.push(`--omowidget--css--foreground--original--value: ${addition?.enable?.colors};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--foreground--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (addition?.enable?.colors) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--foreground--calculated--value) ${importantSyntax};`);
        }
      }
    }

    // Return overrides
    return override;
  }
  private _overrideCssStyleDeclarationBgColor(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--background--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--background--value: initial;`);
    }
    // Set CSS property value (Set user setting, or fallback to original CSS declaration or closest parent's original CSS declaration)
    // (Only if background-color is a solid color)
    if (!declaration.value?.startsWith('rgba') && !declaration.value?.startsWith('hsla') && declaration.value !== 'transparent') {
      override.variables.push(
        `--omowidget--css--background--calculated--value: var(--omowidget--settings--background--value, var(--omowidget--css--background--original--value));`,
      );
    } else {
      override.variables.push(`--omowidget--css--background--calculated--value: var(--omowidget--css--background--original--value);`);
    }

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--background--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Check specific key "bgColors"
      if (exception?.disable?.bgColors || addition?.enable?.bgColors) {
        // Explicit value exception
        if (typeof exception?.disable?.bgColors === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.bgColors as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.bgColors === 'string') {
          override.variables.push(`--omowidget--css--background--original--value: ${addition?.enable?.bgColors};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--background--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (addition?.enable?.bgColors) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--background--calculated--value) ${importantSyntax};`);
        }
      }
      // Check generic key "colors"
      else if (exception?.disable?.colors || addition?.enable?.colors) {
        // Explicit value exception
        if (typeof exception?.disable?.colors === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.colors as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.colors === 'string') {
          override.variables.push(`--omowidget--css--background--original--value: ${addition?.enable?.colors};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--background--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (addition?.enable?.colors) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--background--calculated--value) ${importantSyntax};`);
        }
      }
    }

    // Return overrides
    return override;
  }
  // #endregion

  // #region Reflect state onto root element
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return [
      // Override foreground color set via [style] attribute
      `html.__omowidget__foreground__ [style*="color:"] {\n${[this.overrideCssStyleDeclaration({ name: 'color', value: 'initial !important' })].join('\n')}\n}`,
      // Override background color set via [style] attribute
      `html.__omowidget__background__ [style*="background:"], html.__omowidget__background__ [style*="background-color:"] {\n${[
        this.overrideCssStyleDeclaration({ name: 'background-color', value: 'initial !important' }),
      ].join('\n')}\n}`,
    ].join('\n');
  }

  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (this.active) {
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__background__`);
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__foreground__`);
        removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__colors__');
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__colors__${this.currentValue?.name?.replace(/\s/g, '_')}`);
        if (this.currentValue?.filter) {
          document.getElementsByTagName('html')[0].classList.add(`__omowidget__filter__`);
        }
      } else {
        removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__colors__');
        document.getElementsByTagName('html')[0].classList.remove(`__omowidget__background__`);
        document.getElementsByTagName('html')[0].classList.remove(`__omowidget__foreground__`);
        document.getElementsByTagName('html')[0].classList.remove(`__omowidget__filter__`);
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-background', this.currentValue?.background || '');
      document.getElementsByTagName('html')[0].setAttribute('omowidget-foreground', this.currentValue?.foreground || '');
      document.getElementsByTagName('html')[0].setAttribute('omowidget-filter', this.currentValue?.filter || '');
    } else {
      document.getElementsByTagName('html')[0].classList.remove(`__omowidget__background__`);
      document.getElementsByTagName('html')[0].classList.remove(`__omowidget__foreground__`);
      document.getElementsByTagName('html')[0].classList.remove(`__omowidget__filter__`);
      removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__colors__');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-foreground');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-background');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-filter');
    }
  }
  // #endregion
}

// Export
export const colorsAction = new ColorsAction();
