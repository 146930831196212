import { composeCssSelector, removeClassByPrefix } from '../..';
import {
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
} from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { ControllerService } from '../../ControllerService';
import { BaseAction } from '../BaseAction';
import { fontFamilyAction } from '../FontFamily';

/**
 * Line (Word and Letter) spacing action implementation
 */
class LineSpacingAction extends BaseAction<number> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['lineSpacing', 'letterSpacing', 'wordSpacing'];
  public get config() {
    return ConfigurationService.singleton().config.settings.lineSpacing;
  }

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {
    'word-spacing': '0px',
    'letter-spacing': '0px',
  };

  public className: string[] = ['__omowidget__linespacing__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.lineSpacing.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return this.initialValue !== this.currentValue;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): number {
    return settings.lineSpacing.value.initial;
  }

  public _getCurrentValue(settings: WidgetUserSettings): number | undefined {
    return settings.lineSpacing.value;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean): void {
    settings.lineSpacing.value = this._setMinMaxValue(
      (settings.lineSpacing.value || this.initialValue) + (value ? +1 : -1) * this.config.value.step,
      this.config.value.min,
      this.config.value.max,
    );
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.lineSpacing.value = this.initialValue;
  }
  // #endregion

  // #region Generate action's value as CSS variable
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return globallyEnabled && this.currentValue && this.enabled
      ? `--omowidget--settings--linespacing--value: ${lineSpacingAction.currentValue};`
      : '/* --omowidget--settings--linespacing--value = undefined */';
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    if (exception.disable.lineSpacing) {
      return [
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__letterspacing__'),
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__wordspacing__'),
      ];
    } else {
      if (exception.disable.letterSpacing) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__letterspacing__')];
      }
      if (exception.disable.wordSpacing) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__wordspacing__')];
      }
    }
    return ['__omo__noelement__'];
  }

  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    const letterSpacingDeclaration: WidgetConfigurationStylesheetsDeclaration = {
      name: 'letter-spacing',
    };
    const wordSpacingDeclaration = {
      name: 'word-spacing',
    };
    if (exception.disable.lineSpacing) {
      const overrideLS = this._overrideCssStyleDeclarationLetterSpacing(letterSpacingDeclaration, exception);
      override.variables.push(...overrideLS.variables);
      override.declarations.push(...overrideLS.declarations);
      const overrideWS = this._overrideCssStyleDeclarationWordSpacing(wordSpacingDeclaration, exception);
      override.variables.push(...overrideWS.variables);
      override.declarations.push(...overrideWS.declarations);
    } else {
      if (exception.disable.letterSpacing) {
        const overrideLS = this._overrideCssStyleDeclarationLetterSpacing(letterSpacingDeclaration, exception);
        override.variables.push(...overrideLS.variables);
        override.declarations.push(...overrideLS.declarations);
      }
      if (exception.disable.wordSpacing) {
        const overrideWS = this._overrideCssStyleDeclarationWordSpacing(wordSpacingDeclaration, exception);
        override.variables.push(...overrideWS.variables);
        override.declarations.push(...overrideWS.declarations);
      }
    }

    // Return overrides
    return override;
  }

  public validateException(exception: WidgetConfigurationStylingException): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'letterSpacing', exception.disable.lineSpacing),
    );
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'wordSpacing', exception.disable.lineSpacing),
    );
  }
  // #endregion

  // #region Generate action's additions' declarations
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    if (addition.enable.lineSpacing) {
      return [
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__letterspacing__'),
        composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__wordspacing__'),
      ];
    } else {
      if (addition.enable.letterSpacing) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__letterspacing__')];
      }
      if (addition.enable.wordSpacing) {
        return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__wordspacing__')];
      }
    }
    return ['__omo__noelement__'];
  }

  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    const letterSpacingDeclaration: WidgetConfigurationStylesheetsDeclaration = {
      name: 'letter-spacing',
    };
    const wordSpacingDeclaration = {
      name: 'word-spacing',
    };
    if (addition.enable.lineSpacing) {
      const overrideLS = this._overrideCssStyleDeclarationLetterSpacing(letterSpacingDeclaration, addition);
      override.variables.push(...overrideLS.variables);
      override.declarations.push(...overrideLS.declarations);
      const overrideWS = this._overrideCssStyleDeclarationWordSpacing(wordSpacingDeclaration, addition);
      override.variables.push(...overrideWS.variables);
      override.declarations.push(...overrideWS.declarations);
    } else {
      if (addition.enable.letterSpacing) {
        const overrideLS = this._overrideCssStyleDeclarationLetterSpacing(letterSpacingDeclaration, addition);
        override.variables.push(...overrideLS.variables);
        override.declarations.push(...overrideLS.declarations);
      }
      if (addition.enable.wordSpacing) {
        const overrideWS = this._overrideCssStyleDeclarationWordSpacing(wordSpacingDeclaration, addition);
        override.variables.push(...overrideWS.variables);
        override.declarations.push(...overrideWS.declarations);
      }
    }

    // Return overrides
    return override;
  }

  public validateAddition(addition: WidgetConfigurationStylingAddition): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'letterSpacing', addition.enable.lineSpacing),
    );
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'wordSpacing', addition.enable.lineSpacing),
    );
  }
  // #endregion

  // #region Override CSS declaration
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Process foreground-color overrides
    if (declaration.name === 'letter-spacing') {
      const overrideFg = this._overrideCssStyleDeclarationLetterSpacing(declaration, exceptionOrAdditionConfiguration);
      override.variables.push(...overrideFg.variables);
      override.declarations.push(...overrideFg.declarations);
    }
    // Process background-color overrides
    else if (declaration.name === 'word-spacing') {
      const overrideBg = this._overrideCssStyleDeclarationWordSpacing(declaration, exceptionOrAdditionConfiguration);
      override.variables.push(...overrideBg.variables);
      override.declarations.push(...overrideBg.declarations);
    }

    // Return overrides
    return override;
  }
  private _overrideCssStyleDeclarationLetterSpacing(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if font-family is enabled
    const fontFamilyActionEnabled =
      exceptionOrAdditionConfiguration === undefined || (exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition)?.enable?.fontFamily;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--letterspacing--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--letterspacing--value: initial;`);
      override.variables.push(`--omowidget--settings--letterspacing--multiplier: 1;`);
    }
    // Set CSS property value (Set user setting, or fallback to original CSS declaration or closest parent's original CSS declaration)
    const factors = [
      'var(--omowidget--settings--linespacing--value, 0)',
      ...(fontFamilyActionEnabled ? ['var(--omowidget--settings--letterspacing--multiplier, 1)'] : []),
    ];
    override.variables.push(
      `--omowidget--css--letterspacing--calculated--value: calc( ${
        declaration.value || 'var(--omowidget--css--letterspacing--original--value)'
      } + 1em * (${factors.join(' * ')}));`,
    );

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--letterspacing--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Check specific key "letterSpacing"
      if (exception?.disable?.letterSpacing || addition?.enable?.letterSpacing) {
        // Explicit value exception
        if (typeof exception?.disable?.letterSpacing === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.letterSpacing as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.letterSpacing === 'string') {
          override.variables.push(`--omowidget--css--letterspacing--original--value: ${addition?.enable?.letterSpacing};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--letterspacing--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (addition?.enable?.letterSpacing) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--letterspacing--calculated--value) ${importantSyntax};`);
        }
      }
      // Check generic key "lineSpacing"
      else if (exception?.disable?.lineSpacing || addition?.enable?.lineSpacing) {
        // Explicit value exception
        if (typeof exception?.disable?.lineSpacing === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.lineSpacing as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.lineSpacing === 'string') {
          override.variables.push(`--omowidget--css--letterspacing--original--value: ${addition?.enable?.lineSpacing};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--letterspacing--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (addition?.enable?.lineSpacing) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--letterspacing--calculated--value) ${importantSyntax};`);
        }
      }
    }

    // Return overrides
    return override;
  }
  private _overrideCssStyleDeclarationWordSpacing(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if font-family is enabled
    const fontFamilyActionEnabled =
      exceptionOrAdditionConfiguration === undefined || (exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition)?.enable?.fontFamily;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--wordspacing--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--wordspacing--value: initial;`);
      override.variables.push(`--omowidget--settings--wordspacing--multiplier: 1;`);
    }
    const factors = [
      'var(--omowidget--settings--linespacing--value, 0)',
      ...(fontFamilyActionEnabled ? ['var(--omowidget--settings--wordspacing--multiplier, 1)'] : []),
    ];
    override.variables.push(
      `--omowidget--css--wordspacing--calculated--value: calc( ${
        declaration.value || 'var(--omowidget--css--wordspacing--original--value)'
      } + 1em * (${factors.join(' * ')}));`,
    );

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--wordspacing--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Check specific key "wordSpacing"
      if (exception?.disable?.wordSpacing || addition?.enable?.wordSpacing) {
        // Explicit value exception
        if (typeof exception?.disable?.wordSpacing === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.wordSpacing as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.wordSpacing === 'string') {
          override.variables.push(`--omowidget--css--wordspacing--original--value: ${addition?.enable?.wordSpacing};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--wordspacing--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (typeof addition?.enable?.wordSpacing) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--wordspacing--calculated--value) ${importantSyntax};`);
        }
      }
      // Check generic key "lineSpacing"
      else if (exception?.disable?.lineSpacing || addition?.enable?.lineSpacing) {
        // Explicit value exception
        if (typeof exception?.disable?.lineSpacing === 'string') {
          override.declarations.push(`${declaration.name}: ${exception?.disable.lineSpacing as string} ${importantSyntax};`);
        }
        // Explicit value addition
        if (typeof addition?.enable?.lineSpacing === 'string') {
          override.variables.push(`--omowidget--css--wordspacing--original--value: ${addition?.enable?.lineSpacing};`);
          override.declarations.push(`${declaration.name}: var(--omowidget--css--wordspacing--calculated--value) ${importantSyntax};`);
        }
        // Non-explicit value addition
        else if (typeof addition?.enable?.lineSpacing) {
          override.declarations.push(`${declaration.name}: var(--omowidget--css--wordspacing--calculated--value) ${importantSyntax};`);
        }
      }
    }

    // Return overrides
    return override;
  }
  // #endregion

  // #region Reflect state onto root element
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return [
      // Override letter spacing set via [style] attribute
      `html.__omowidget__letterspacing__ [style*="letter-spacing:"] {\n${[
        this.overrideCssStyleDeclaration({ name: 'letter-spacing', value: '0px !important' }),
      ].join('\n')}\n}`,
      // Override word spacing color set via [style] attribute
      `html.__omowidget__wordspacing__ [style*="word-spacing:"] {\n${[this.overrideCssStyleDeclaration({ name: 'word-spacing', value: '0px !important' })].join(
        '\n',
      )}\n}`,
    ].join('\n');
  }

  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (
        this.active ||
        (fontFamilyAction.currentValue?.letterSpacingFactor !== undefined && fontFamilyAction.currentValue.letterSpacingFactor !== 1) ||
        (fontFamilyAction.currentValue?.wordSpacingFactor !== undefined && fontFamilyAction.currentValue.wordSpacingFactor !== 1)
      ) {
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__letterspacing__`);
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__wordspacing__`);
        document.getElementsByTagName('html')[0].classList.add(`__omowidget__linespacing__`);
      } else {
        removeClassByPrefix(document.getElementsByTagName('html')[0], '__omowidget__linespacing__');
        document.getElementsByTagName('html')[0].classList.remove(`__omowidget__letterspacing__`);
        document.getElementsByTagName('html')[0].classList.remove(`__omowidget__wordspacing__`);
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-linespacing', this.currentValue?.toString() || '');
    } else {
      document.getElementsByTagName('html')[0].classList.remove(`__omowidget__letterspacing__`);
      document.getElementsByTagName('html')[0].classList.remove(`__omowidget__wordspacing__`);
      document.getElementsByTagName('html')[0].classList.remove(`__omowidget__linespacing__`);
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-linespacing');
    }
  }
  // #endregion
}

// Export
export const lineSpacingAction = new LineSpacingAction();
