import { colorsAction } from './Colors';
import { fontFamilyAction } from './FontFamily';
import { fontSizeAction } from './FontSize';
import { fontWeightAction } from './FontWeight';
import { globalEnabledAction } from './GlobalEnabled';
import { lineHeightAction } from './LineHeight';
import { lineSpacingAction } from './LineSpacing';
import { linkHighlightAction } from './LinkHighlight';

// Actions
export const actions = [
  globalEnabledAction,
  fontFamilyAction,
  fontSizeAction,
  lineHeightAction,
  fontWeightAction,
  lineSpacingAction,
  colorsAction,
  linkHighlightAction,
];
