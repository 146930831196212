// Import dependencies
import { h } from 'preact';
import { BaseComponent } from '../BaseComponent';

/**
 * Property type
 */
type MenuItemProps = {
  isMainIcon?: boolean;
  bgColor?: string;
  class?: string;
  colorFill?: boolean;
  colorBorder?: boolean;
  colorShadow?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  colorDisabled?: boolean;
  tothem?: boolean;
};

/**
 * Menu item component
 */
export class MenuItemComponent extends BaseComponent<MenuItemProps, {}> {
  public render() {
    return (
      <div class={`__omowidget__host__menu_item ${this.props.class || ''}  ${this.props.disabled ? 'disabled' : ''}`} onClick={this.props.onClick}>
        <div
          class={`__omowidget__host__menu_item_container ${this.props.class || ''} ${this.props.tothem ? 'tothem' : ''} ${
            this.props.colorFill ? 'colorFill' : ''
          } ${this.props.colorBorder ? 'colorBorder' : ''} ${this.props.colorBorder ? 'colorBorder' : ''} ${this.props.bgColor === '' ? 'noPadding' : ''} ${
            this.props.colorShadow ? 'colorShadow' : ''
          } ${this.props.colorDisabled ? 'colorDisabled' : ''} ${this.props.isMainIcon ? '' : 'border mainIcon'} ${this.props.disabled ? 'disabled' : ''}`}
          style={`background: ${this.props.bgColor ? `${this.props.bgColor} !important` : ''}`}
        >
          <div class="__omowidget__host__menu_item_content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
