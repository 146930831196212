import {
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  WidgetConfigurationStylesheetsGlobalDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
  ExceptionOrAdditionValidationErrorDescription,
} from '../../../../../common/types';
import { UserSettingsStore } from '../../../stores';
import { ConfigurationService } from '../../ConfigurationService';

/**
 * Base Action for every other setting action
 */
export class BaseAction<TValue> {
  // #region Action description
  /**
   * Action configuration property key
   */
  public configurationPropertyKeys: string[] = [];

  /**
   * Get's action configuration
   */
  public get config(): any {
    throw new Error('Not implemented!');
  }

  /**
   * Default CSS declaration names and default values
   */
  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {};
  /**
   * Action class names to be applied to the root element
   */
  public className: string[] = [];
  // #endregion

  // #region Enabled and Active statuses
  /**
   * Gets action's enabled status
   */
  public get enabled(): boolean {
    throw new Error('Not implemented!');
  }

  /**
   * Check if setting is active
   */
  public get active(): boolean {
    return this.enabled && this._activeRegardlessOfEnabled;
  }
  /**
   * Get active property without considering enabled property
   */
  protected get _activeRegardlessOfEnabled(): boolean {
    throw new Error('Not implemented!');
  }
  // #endregion

  // #region Value management (initial/current)
  /**
   * Get initial action value
   */
  public get initialValue(): TValue {
    return this._getInitialValue(ConfigurationService.singleton().config.settings);
  }
  /**
   * Get initial action value
   * @param settings Settings configuration
   */
  public _getInitialValue(settings: SettingsConfiguration): TValue {
    throw new Error('Not implemented!');
  }

  /**
   * Gets persisted current action value
   */
  public get currentValue(): TValue | undefined {
    const userSettingsStore = UserSettingsStore.getSingletonInstance<WidgetUserSettings>();
    if (userSettingsStore.data) {
      return this._getCurrentValue(userSettingsStore.data);
    }
  }
  /**
   * Gets persisted current action value
   * @param settings User settings store data
   */
  public _getCurrentValue(settings: WidgetUserSettings): TValue | undefined {
    throw new Error('Not implemented!');
  }
  /**
   * Sets and persists current action value
   * @param value Value to set, or true/false mapping to increase/decrease
   */
  public setCurrentValue(value?: boolean | TValue): void {
    UserSettingsStore.getSingletonInstance<WidgetUserSettings>().update((settings?: WidgetUserSettings) => {
      if (settings && value !== undefined) {
        this._setCurrentValue(settings, value);
      }
    });
  }
  /**
   * Sets and persists current action value
   * @param settings User settings store data
   * @param value Value to set, or true/false mapping to increase/decrease
   */
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean | TValue): void {
    throw new Error('Not implemented!');
  }
  /**
   * Gates a value between optional mi and max values
   * @param value Value to gate
   * @param min Min value allowed
   * @param max Max value allowed
   */
  protected _setMinMaxValue(value: number, min?: number, max?: number): number {
    return min !== undefined && value < min ? min : max !== undefined && value > max ? max : value;
  }

  /**
   * Reset setting value to the initial value
   */
  public reset(): void {
    UserSettingsStore.getSingletonInstance<WidgetUserSettings>().update((settings?: WidgetUserSettings) => {
      if (settings) {
        this._resetValue(settings);
      }
    });
  }
  /**
   * Reset method for inheriting actions to override
   * @param settings User settings
   */
  protected _resetValue(settings: WidgetUserSettings) {
    throw new Error('Not implemented!');
  }
  // #endregion

  // #region Generate action's value as CSS variable
  /**
   * Generates current syntax for css variables
   * @param globalEnabledAction Instance of global-enabled action
   */
  public generateVariablesCurrentSyntax(globalEnabledAction: BaseAction<boolean>): string | undefined {
    const globallyEnabled = !globalEnabledAction.enabled || globalEnabledAction.currentValue || false;
    return this._generateVariablesCurrentSyntax(globallyEnabled);
  }
  /**
   * Generates current syntax for css variables
   * @param globallyEnabled If global enabled action is active, or not at all enabled
   */
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return undefined;
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  /**
   * Generates selectors for an exception
   * @param exception Exception configuration
   * @param selector Single selectors for the exception
   */
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    return [];
  }
  /**
   * Generates exception
   * @param exception Exception configuration
   */
  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    return { variables: [], declarations: [] };
  }

  /**
   * Validates addition with explicitly set values against selected, runtime calculated CSS values
   * @param addition Addition to validate
   */
  public validateException(exception: WidgetConfigurationStylingException): void {}
  // #endregion

  // #region Generate action's additions' declarations
  /**
   * Generates selectors for an addition
   * @param addition Addition configuration
   * @param selector Single selectors for the addition
   */
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    return [];
  }
  /**
   * Generates addition
   * @param addition Addition configuration
   */
  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    return { variables: [], declarations: [] };
  }

  /**
   * Validates addition with explicitly set values against selected, runtime calculated CSS values
   * @param addition Addition to validate
   */
  public validateAddition(addition: WidgetConfigurationStylingAddition): void {}
  // #endregion

  // #region Action's exception/addition explicit value validation
  /**
   * Validates a declaration with explicitly set values against selected, runtime calculated CSS values
   * @param exceptionOrAddition Exception/Addition being validated
   * @param selectors Selectors fetching elements to validate against
   * @param key CSS property key to validate
   * @param value CSS property value to validate
   */
  protected _validateDeclaration(
    exceptionOrAddition: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
    key: keyof CSSStyleDeclaration,
    value: string | boolean | undefined,
  ): ExceptionOrAdditionValidationErrorDescription | undefined {
    // Collect elements
    const els: HTMLElement[] = [];
    document.querySelectorAll(exceptionOrAddition.selectors.join(', ')).forEach((el: Element) => {
      if (el instanceof HTMLElement) {
        els.push(el);
      }
    });

    // Collect element's CSS values
    const [cssValues, calculatedCssValues]: Record<string, HTMLElement[]>[] = [...els].reduce(
      ([cssValues, calculatedCssValues]: Record<string, HTMLElement[]>[], el: HTMLElement) => {
        // Get CSS value
        const value = el.style[key];
        if (value) {
          if (!cssValues[value.toString()]) {
          }
          cssValues[value.toString()] = [];
          cssValues[value.toString()].push(el);
        }
        // Get calculated value
        const calculatedValue = getComputedStyle(el)[key];
        if (calculatedValue) {
          if (!calculatedCssValues[calculatedValue.toString()]) {
            calculatedCssValues[calculatedValue.toString()] = [];
          }
          calculatedCssValues[calculatedValue.toString()].push(el);
        }
        return [cssValues, calculatedCssValues];
      },
      [{}, {}],
    );

    // Collect all values
    const allValues = [...Object.keys(cssValues), ...Object.keys(calculatedCssValues)];

    // Validate exception/addition with no explicitly set values
    if (value === true) {
      if (!allValues.length) {
        // Prompt validation
        return {
          instance: exceptionOrAddition,
          message: [
            `You\'re trying to apply an exception/addition without an explicitly configured value to a selector resolving into element(s) with no predefined value(s) of their own.`,
            `Doing this will allow outside style to leak into the exception/addition and there is no predefined value(s) to override the leaked values!`,
            ``,
            `SUGGESTION: Use an explicit value in your exception/addition configuration!`,
          ].join('\n'),
          key,
          value: value.toString(),
          cssValues,
          calculatedCssValues,
        };
      }
    }

    // Validate exception/addition with explicitly set values
    if (typeof value === 'string') {
      if (!!allValues.find(v => v !== value)) {
        // Prompt validation
        return {
          instance: exceptionOrAddition,
          message: [
            `You\'re trying to apply an exception/addition with an explicitly configured value of "${value.toString()}" to a selector resolving into element(s) with different value(s).`,
            `Doing this will cause the exception/addition element to change from it's value into "${value.toString()}" when the "${key}" action is activated!`,
            ...[allValues.length === 1 ? ['', `SUGGESTION: Use the ${allValues[0]} value in your configuration instead!`].join('\n') : ''],
          ].join('\n'),
          key,
          value: value.toString(),
          cssValues,
          calculatedCssValues,
        };
      }
    }
  }
  // #endregion

  // #region Override CSS declaration
  /**
   * Override css style declaration when setting changes
   * @param declaration Css declaration
   * @param exceptionOrAdditionConfiguration (Optional) Exception or Addition configuration
   */
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    return { variables: [], declarations: [] };
  }
  // #endregion

  // #region Reflect state onto root element
  /**
   * Generates syntax for style attribute
   */
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return undefined;
  }

  /**
   * Updates  class list and attributes depending on widget state
   * @param widgetClosed If widget is open or closed
   */
  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {}
  // #endregion

  // #region CSS syntax: "rem" unit mitigation syntax
  public overrideRemDeclaration(remDeclaration: WidgetConfigurationStylesheetsGlobalDeclaration): string {
    const declaration = [`${remDeclaration.selectors.join(', ')} {`, `  ${remDeclaration.name}: ${remDeclaration.value || 'initial'};`, `}`];
    if (remDeclaration.media) {
      return [`@media ${remDeclaration.media} {`, ...declaration.map(line => `  ${line}`), `}`].join('\n');
    } else {
      return declaration.join('\n');
    }
  }
  // #endregion
}

// Export
export const baseAction = new BaseAction();
