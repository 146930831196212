import { composeCssSelector } from '../..';
import {
  SettingsConfigurationLineHeightBehaviors,
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
} from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { ControllerService } from '../../ControllerService';
import { BaseAction } from '../BaseAction';
import { fontFamilyAction } from '../FontFamily';

/**
 * Line height action implementation
 */
class LineHeightAction extends BaseAction<number> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['lineHeight'];
  public get config() {
    return ConfigurationService.singleton().config.settings.lineHeight;
  }

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {
    'line-height': (el: Element) => window.getComputedStyle(el, null).lineHeight,
  };

  public className: string[] = ['__omowidget__lineheight__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.lineHeight.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return this.initialValue !== this.currentValue;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): number {
    return settings.lineHeight.value.initial;
  }

  public _getCurrentValue(settings: WidgetUserSettings): number | undefined {
    return settings.lineHeight.value;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean): void {
    settings.lineHeight.value = this._setMinMaxValue(
      (settings.lineHeight.value || this.initialValue) + (value ? +1 : -1) * this.config.value.step,
      this.config.value.min,
      this.config.value.max,
    );
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.lineHeight.value = this.initialValue;
  }
  // #endregion

  // #region Generate action's value as CSS variable
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return globallyEnabled && this.currentValue && this.enabled
      ? `--omowidget--settings--lineheight--value: ${lineHeightAction.currentValue};`
      : '/* --omowidget--settings--lineheight--value = undefined */';
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    if (exception.disable.lineHeight) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__lineheight__')];
    }
    return ['__omo__noelement__'];
  }

  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && exception.disable.lineHeight) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'line-height',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, exception);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateException(exception: WidgetConfigurationStylingException): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'lineHeight', exception.disable.lineHeight),
    );
  }
  // #endregion

  // #region Generate action's additions' declarations
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    if (addition.enable.lineHeight) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__lineheight__')];
    }
    return ['__omo__noelement__'];
  }

  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && addition.enable.lineHeight) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'line-height',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, addition);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateAddition(addition: WidgetConfigurationStylingAddition): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'lineHeight', addition.enable.lineHeight),
    );
  }
  // #endregion

  // #region Override CSS declaration
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    const fontFamilyActionEnabled =
      exceptionOrAdditionConfiguration === undefined || (exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition)?.enable?.fontFamily;
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--lineheight--original--value: ${declaration.value};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--lineheight--value: initial;`);
      override.variables.push(`--omowidget--settings--lineheight--multiplier: 1;`);
    }

    // Check if declaration value is scalable
    if (declaration.value === undefined || !isNaN(parseFloat(declaration.value))) {
      // Scale all line-heights linearly
      if (this.config.behavior === SettingsConfigurationLineHeightBehaviors.LINEAR_SCALING) {
        // Compose full CSS value (with units) from user setting and limit the composed value to only be larger than the original value
        // (or invalid variable if no setting)
        const factors = [
          'var(--omowidget--css--lineheight--original--value)',
          'var(--omowidget--settings--lineheight--value, 1)',
          fontFamilyActionEnabled ? 'var(--omowidget--settings--lineheight--multiplier, 1)' : '1',
        ];
        const calculatedSyntax = `calc(${factors.join(' * ')})`;
        override.variables.push(`--omowidget--css--lineheight--calculated--value: ${calculatedSyntax};`);
      }

      // Scale all line-heights linearly with line-height and font-size settings
      else if (this.config.behavior === SettingsConfigurationLineHeightBehaviors.LINEAR_SCALING_WITH_FONTSIZE) {
        // Compose full CSS value (with units) from user setting and limit the composed value to only be larger than the original value
        // (or invalid variable if no setting)
        const factors = [
          'var(--omowidget--css--lineheight--original--value)',
          'var(--omowidget--settings--lineheight--value, 1)',
          'var(--omowidget--settings--fontsize--value, 1)',
          fontFamilyActionEnabled ? 'var(--omowidget--settings--lineheight--multiplier, 1)' : '1',
        ];
        const calculatedSyntax = `calc(${factors.join(' * ')})`;
        override.variables.push(`--omowidget--css--lineheight--calculated--value: ${calculatedSyntax};`);
      }
    }

    // If original CSS value is not scalable leave as is
    else {
      override.variables.push(`/* Line-Height: Unscalable: */`);
      override.variables.push(`--omowidget--css--lineheight--calculated--value: var(--omowidget--css--lineheight--original--value);`);
    }

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--lineheight--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Explicit value exception
      if (typeof exception?.disable?.lineHeight === 'string') {
        override.declarations.push(`${declaration.name}: ${exception?.disable.lineHeight as string} ${importantSyntax};`);
      }
      // Explicit value addition
      if (typeof addition?.enable?.lineHeight === 'string') {
        override.variables.push(`--omowidget--css--lineheight--original--value: ${addition?.enable?.lineHeight};`);
        override.declarations.push(`${declaration.name}: var(--omowidget--css--lineheight--calculated--value) ${importantSyntax};`);
      }
      // Non-explicit value addition
      else if (addition?.enable?.lineHeight) {
        override.declarations.push(`${declaration.name}: var(--omowidget--css--lineheight--calculated--value) ${importantSyntax};`);
      }
    }

    // Return overrides
    return override;
  }
  // #endregion

  // #region Reflect state onto root element
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return `html.__omowidget__lineheight__ [style*="font:"], html.__omowidget__lineheight__ [style*="line-height:"] {\n${[
      this.overrideCssStyleDeclaration({
        name: 'line-height',
        value: '1em !important',
      }),
    ].join('\n')}\n}`;
  }

  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (this.active || (fontFamilyAction.currentValue?.lineHeightFactor !== undefined && fontFamilyAction.currentValue?.lineHeightFactor !== 1)) {
        document.getElementsByTagName('html')[0].classList.add('__omowidget__lineheight__');
      } else {
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__lineheight__');
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-lineheight', this.currentValue?.toString() || '');
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-lineheight');
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__lineheight__');
    }
  }
  // #endregion

  /**
   * Checks if localStorage setting behavior differs from configuration setting behavior and if does, localStorage value falls back to initial configuration value
   * @param localStorageSettings User settings in local storage
   */
  public checkSettingValuePerBehavior(localStorageSettings: any) {
    if (localStorageSettings) {
      if (this.config.behavior !== localStorageSettings.lineHeight.behavior) {
        localStorageSettings.lineHeight.value = this.initialValue;
        localStorageSettings.lineHeight.behavior = this.config.behavior;
      }
    }
    return localStorageSettings;
  }
}

// Export
export const lineHeightAction = new LineHeightAction();
