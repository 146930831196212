/* eslint-disable react/prefer-stateless-function */
import { Component, h } from 'preact';

/**
 * Menu item component
 */
export abstract class BaseComponent<TProps = {}, TState = {}> extends Component<TProps, TState> {
  /**
   * Isolates a style definition to a single element being applied to
   */
  protected isolateStyle(style: Record<string, string>): string {
    // Return processed style as a string
    let isolatedStyle = Object.keys(style)
      .map(key => `${key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)}:${style[key]} !important`)
      .join(';');

    isolatedStyle.concat(';');
    return isolatedStyle;
  }
}
