// Import dependencies
import { BootstrapService } from './services';

// Async initialization
if (document.readyState === 'complete') {
  bootstrap();
} else {
  window.addEventListener('load', bootstrap);
}

async function bootstrap() {
  // Initialize widget
  await BootstrapService.initialize();
  // Bootstrap widget (if enabled)
  await BootstrapService.bootstrap();
}
