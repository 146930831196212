import { SettingsConfiguration, WidgetUserSettings } from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { BaseAction } from '../BaseAction';
import { globalEnabledAction } from '../GlobalEnabled';

/**
 * Link highlighting action implementation
 */
class LinkHighlightAction extends BaseAction<boolean> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['linkHighlight'];

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {};

  public className: string[] = ['__omowidget__linkhighlight__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.linkHighlight.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return this.currentValue === true;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): boolean {
    return false;
  }

  public _getCurrentValue(settings: WidgetUserSettings): boolean | undefined {
    return settings.linkHighlight.value;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean): void {
    settings.linkHighlight.value = value;
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.linkHighlight.value = this.initialValue;
  }
  // #endregion

  // #region Reflect state onto root element
  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (this.active) {
        if (globalEnabledAction.active) {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__active__');
          document.getElementsByTagName('html')[0].classList.add('__omowidget__links__active__');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__active__');
        }
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__enabled__');
        document.getElementsByTagName('html')[0].classList.add('__omowidget__links__enabled__');
      } else {
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__enabled__');
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__active__');
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-linkhighlight', '');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__enabled__');
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__links__active__');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-linkhighlight');
    }
  }
  // #endregion
}

// Export
export const linkHighlightAction = new LinkHighlightAction();
