// Import dependencies
import {
  WidgetUserSettings,
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  ExceptionOrAdditionValidationErrorDescription,
} from '../../../../common/types';
import { baseAction } from '../actions/BaseAction';
import { BootstrapService } from '../Bootstrap';
import { UserSettingsStore } from '../../stores';
import { ConfigurationService } from '..';

/**
 * Provides outside control of the widget
 */
export class ControllerService {
  private _scriptEl?: HTMLOrSVGScriptElement;

  private _lang: string;

  private _callbackFn: any;

  private static _service?: ControllerService;

  private _exceptionAndAdditionValidationCallbacks: (() => ExceptionOrAdditionValidationErrorDescription | undefined)[] = [];

  /**
   * Gets singleton instance of the service
   * @param script Script element reference needed for initial instantiation of the singleton instance
   */
  public static singleton(scriptEl?: HTMLOrSVGScriptElement) {
    return ControllerService._service || (ControllerService._service = new ControllerService(scriptEl));
  }

  constructor(scriptEl?: HTMLOrSVGScriptElement) {
    // Store script element
    this._scriptEl = scriptEl;
    // Set language
    const language = this._scriptEl?.getAttribute('lang');
    this._lang = language && Object.keys(ConfigurationService.singleton().config.lang).includes(language) ? language : 'hr';
  }

  public initialize(callbackFn: any) {
    this._callbackFn = callbackFn;
  }

  /**
   * Get language
   */
  public get lang(): string {
    return this._lang;
  }
  /**
   * Set language
   */
  public set lang(lang: string) {
    this._lang = lang && Object.keys(ConfigurationService.singleton().config.lang).includes(lang) ? lang : 'hr';
    this._callbackFn();
  }

  /**
   * Gets current enabled (opened) state of widget
   */
  public get opened(): boolean {
    return !this.closed;
  }
  /**
   * Sets  current enabled (opened) state of widget
   */
  public set opened(value: boolean) {
    this.closed = !value;
  }

  /**
   * Gets current enabled (closed) state of widget
   */
  public get closed(): boolean {
    return UserSettingsStore.getSingletonInstance<WidgetUserSettings>().data?.closed.value !== undefined
      ? UserSettingsStore.getSingletonInstance<WidgetUserSettings>().data?.closed.value!
      : true;
  }
  /**
   * Sets  current enabled (closed) state of widget
   */
  public set closed(value: boolean) {
    // Update enabled status
    UserSettingsStore.getSingletonInstance<WidgetUserSettings>().update((settings?: WidgetUserSettings) => {
      if (settings) {
        settings.closed.value = value;
      }
    });
    // If (re)enabled, bootstrap widget
    BootstrapService.bootstrap();
  }

  /**
   * Registers an exception/addition validation function to be called when validating runtime exceptions/additions
   * @param callback Exception/Addition validation function
   */
  public _registerExceptionAndAdditionValidationCallback(callBackFn: () => ExceptionOrAdditionValidationErrorDescription | undefined) {
    this._exceptionAndAdditionValidationCallbacks.push(callBackFn);
  }

  /**
   * Performs runtime validation
   */
  public validate(clear = true) {
    // Get configuration
    const config = ConfigurationService.singleton().config;

    // Clear console if requested
    if (clear) {
      console.clear();
    }

    // Ready specific validation functions
    const fns: Record<string, () => void> = {};

    // Validate "rem" units mitigation
    const remDeclarations = config.stylesheets.analysis.reduce((declarations: string[], stylesheet) => {
      declarations.push(...stylesheet.remUsages.map(declaration => baseAction.overrideRemDeclaration(declaration).replace(/\n/g, ' ')));
      return declarations;
    }, []);
    if (config.stylesheets.lockRemValues !== !!remDeclarations.length) {
      console.warn(
        '- OMO WIDGET found potential issues with "rem" unit mitigation! Run "__omowidget__.validate().remMitigationValidation() to get more details ..."',
      );
      fns['remMitigationValidation'] = () => {
        if (!config.stylesheets.lockRemValues && remDeclarations.length) {
          console.warn(
            `- "lockRemValues" configuration option is not enabled and there are ${remDeclarations.length} declarations using "rem" unit detected on the site!`,
          );
          console.warn('  ... detected declarations:', remDeclarations);
        }
        if (config.stylesheets.lockRemValues && !remDeclarations.length) {
          console.warn(`- "lockRemValues" configuration option is enabled yet there are no declarations using "rem" unit detected on the site!`);
        }
      };
    }

    // Validate exceptions/additions
    const errs: ExceptionOrAdditionValidationErrorDescription[] = [];
    for (const callbackFn of this._exceptionAndAdditionValidationCallbacks) {
      const err = callbackFn();
      if (err) {
        errs.push(err);
      }
    }
    // Output exceptions/additions error messages
    if (errs.length) {
      console.warn(
        '- OMO WIDGET found potential issues with the exceptions/additions configuration! Run "__omowidget__.validate().exceptionsAndAdditionsValidation() to get more details ..."',
      );
      fns['exceptionsAndAdditionsValidation'] = () => {
        for (const err of errs) {
          const isException = !!(err.instance as WidgetConfigurationStylingException).disable;
          console.warn(
            [
              `- ${isException ? 'Exception' : 'Addition'} with selector "${err.instance.selectors.join(', ')}", configuration for "${err.key}"`,
              `  ... is configured with value: ${err.value}`,
              `  ... and is applied to elements with:`,
              err.message,
            ].join('\n'),
          );
          if (Object.keys(err.cssValues).length) {
            console.warn(`    - configured CSS value(s):`, Object.keys(err.cssValues).join(', '));
            console.warn(`      `, err.cssValues);
          }
          if (Object.keys(err.calculatedCssValues).length) {
            console.warn(`    - calculated CSS value(s):`, Object.keys(err.calculatedCssValues).join(', '));
            console.warn(`      `, err.calculatedCssValues);
          }
          console.warn();
        }
      };
    }

    // Return specific validation functions
    return fns;
  }
}
