// Import dependencies
import { WidgetUserSettings } from '../../../../common/types';
import { UserSettingsStore } from '../../stores';
import { actions } from '../actions';
import { globalEnabledAction } from '../actions/GlobalEnabled';

/**
 * Setting service
 */
export class SettingService {
  /**
   * Subscribes to widget (user) settings change
   */
  public static subscribeToWidgetSettingsChange() {
    UserSettingsStore.getSingletonInstance<WidgetUserSettings>().subscribe((settings?: WidgetUserSettings) => {
      if (settings) {
        // Updates  class list & attributes
        SettingService.updateHtmlClassList(settings);
      }
    });
  }
  /**
   * Updates  class list and attributes based on settings
   * @param settings User settings
   */
  public static updateHtmlClassList(settings?: WidgetUserSettings) {
    if (settings) {
      // If widget is enabled, set class list and attributes
      if (settings.closed.value === false) {
        // Enabled
        if (!document.getElementsByTagName('html')[0].classList.contains('__omowidget__enabled')) {
          document.getElementsByTagName('html')[0].classList.add('__omowidget__enabled');
        }
        if (!isAnySettingActive()) {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__empty___');
          document.getElementsByTagName('html')[0].classList.add('__omowidget__empty___');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__empty___');
        }
        if (isAnySettingActive() && globalEnabledAction.active) {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__active__');
          document.getElementsByTagName('html')[0].classList.add('__omowidget__active__');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__active__');
        }
        actions.forEach(action => {
          action.updateHtmlClassListAndAttributes(settings.closed.value);
        });
      } else {
        actions.forEach(action => {
          action.updateHtmlClassListAndAttributes(settings.closed.value && document.getElementsByTagName('html')[0].classList.contains('__omowidget__enabled'));
        });
        // If widget is disabled, remove attributes and classes
        if (document.getElementsByTagName('html')[0].classList.contains('__omowidget__enabled')) {
          // Remove classes
          document.getElementsByTagName('html')[0].classList.remove('__omowidget__enabled');
        }
      }
    }
  }
}

// Removes classes by prefix
export function removeClassByPrefix(el: any, prefix: string) {
  let newClassList: any[] = [];

  el.classList.forEach((className: string | any[]) => {
    if (className.indexOf(prefix) !== 0) {
      newClassList.push(className);
    }
  });
  el.className = newClassList.join(' ');
}

export function isAnySettingActive() {
  const temp = [...actions];
  temp.shift();
  for (let action of temp) {
    if (action.active === true) {
      return true;
    }
  }
  return false;
}
