// Import dependencies
import { WidgetUserSettings } from '../../../../common/types';
import { ControllerService } from '../../services';
import { colorsAction } from '../../services/actions/Colors';
import { fontFamilyAction } from '../../services/actions/FontFamily';
import { fontSizeAction } from '../../services/actions/FontSize';
import { fontWeightAction } from '../../services/actions/FontWeight';
import { globalEnabledAction } from '../../services/actions/GlobalEnabled';
import { lineHeightAction } from '../../services/actions/LineHeight';
import { lineSpacingAction } from '../../services/actions/LineSpacing';
import { linkHighlightAction } from '../../services/actions/LinkHighlight';
import { SingletonStore } from '../../services/Store';

/**
 * Store containing user settings
 */
export class UserSettingsStore extends SingletonStore<WidgetUserSettings> {
  /**
   * Detects and initializes starting user settings
   * @param subscribe If true, will subscribe to changes and store them to local storage
   */
  public static initialize(subscribe = false) {
    // Get initial settings
    const initialSettings = getInitialUserSettings();
    // Load initial data
    UserSettingsStore.getSingletonInstance<WidgetUserSettings>().update(() => {
      // Check if behavior has changed
      let localStorageSettings = JSON.parse(window.localStorage.getItem('__omowidget__user__settings__') as string);
      localStorageSettings = lineHeightAction.checkSettingValuePerBehavior(localStorageSettings);
      localStorageSettings = fontSizeAction.checkSettingValuePerBehavior(localStorageSettings);
      // Load data
      return UserSettingsStore.checkLoadedSettings(localStorageSettings || initialSettings);
    });
    // Store data on change
    if (subscribe) {
      UserSettingsStore.getSingletonInstance<WidgetUserSettings>().subscribe(settings => {
        localStorage.setItem('__omowidget__user__settings__', JSON.stringify(settings));
      });
    }
  }

  /**
   * Checks if settings loaded from local-storage are missing properties
   * @param settings Loaded settings
   * @returns Settings with correct structure ensured
   */
  private static checkLoadedSettings(settings: WidgetUserSettings) {
    // TODO: Check if settings loaded from local-storage are missing properties
    const initialSettings = getInitialUserSettings();
    return { ...initialSettings, ...settings };
  }
}

/**
 * Default initial user settings
 */
function getInitialUserSettings(): WidgetUserSettings {
  return {
    globalEnabled: {
      value: globalEnabledAction.initialValue,
    },
    closed: {
      value: false,
    },
    linkHighlight: {
      value: linkHighlightAction.initialValue,
    },
    lineSpacing: {
      value: lineSpacingAction.initialValue,
    },
    fontFamily: {
      abbreviation: fontFamilyAction.initialValue.abbreviation,
      name: fontFamilyAction.initialValue.name,
      value: fontFamilyAction.initialValue.value,
    },
    fontSize: {
      behavior: fontSizeAction.config.behavior,
      value: fontSizeAction.initialValue,
    },
    fontWeight: {
      value: fontWeightAction.initialValue.value,
    },
    lineHeight: {
      behavior: lineHeightAction.config.behavior,
      value: lineHeightAction.initialValue,
    },
    colors: {
      name: colorsAction.initialValue.name,
      foreground: colorsAction.initialValue.foreground,
      background: colorsAction.initialValue.background,
    },
  };
}
