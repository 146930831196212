// Import dependencies
import preact, { h } from 'preact';
import { SettingsConfiguration, WidgetUserSettings } from '../../../../common/types';
import { BaseComponent } from '../BaseComponent';
import { ConfigurationService, ControllerService, isAnySettingActive } from '../../services';
import { SubmenuComponent } from '../SubmenuComponent';
import { UserSettingsStore } from '../../stores';
import { MenuItemComponent } from '../MenuItemComponent';

// Import icons
import svgIconOff from '!!raw-loader!../../assets/icons/icon-off.svg';
import svgIconOn from '!!raw-loader!../../assets/icons/icon-on.svg';
import svgIconLineHeight from '!!raw-loader!../../assets/icons/icon-line-height.svg';
import svgIconFontSize from '!!raw-loader!../../assets/icons/icon-font-size.svg';
import svgIconFontFamily from '!!raw-loader!../../assets/icons/omo-type.svg';
import svgIconPlus from '!!raw-loader!../../assets/icons/up.svg';
import svgIconMinus from '!!raw-loader!../../assets/icons/down.svg';
import svgIconClose from '!!raw-loader!../../assets/img/close.svg';
import svgIconPower from '!!raw-loader!../../assets/icons/power.svg';
import svgIconFontWeight from '!!raw-loader!../../assets/icons/icon-font-weight.svg';
import svgIconLinkHighlight from '!!raw-loader!../../assets/icons/link.svg';
import svgIconSpacing from '!!raw-loader!../../assets/icons/icon-spacing.svg';

//Import font family icons
import omoTypeD from '!!raw-loader!../../assets/icons/omotype-d.svg';
import omoTypeB from '!!raw-loader!../../assets/icons/omotype-b.svg';

// Import CSS
import widgetGlobalCssSyntax from '../../style/index.scss';
import widgetMainCssSyntax from '../WidgetShellComponent/style.scss';
import widgetSubmenuCssSyntax from '../SubmenuComponent/style.scss';
import widgetItemCssSyntax from '../MenuItemComponent/style.scss';
import messageComponentCssSyntax from '../MessageComponent/style.scss';
import { fontSizeAction } from '../../services/actions/FontSize';
import { lineHeightAction } from '../../services/actions/LineHeight';
import { lineSpacingAction } from '../../services/actions/LineSpacing';
import { linkHighlightAction } from '../../services/actions/LinkHighlight';
import { globalEnabledAction } from '../../services/actions/GlobalEnabled';
import { fontWeightAction } from '../../services/actions/FontWeight';
import { fontFamilyAction } from '../../services/actions/FontFamily';
import { colorsAction } from '../../services/actions/Colors';

/**
 * Property types
 */
type SettingKey = 'lineHeight' | 'fontFamily' | 'fontSize' | 'colors' | 'fontWeight' | 'linkHighlight' | 'lineSpacing' | 'power';
type WidgetShellComponentState = {
  expanded: boolean;
  expandedSubmenu?: SettingKey;
  targetedItem?: number;
  closedBecauseReset: boolean;
};

/**
 * Widget shell component
 */
export class WidgetShellComponent extends BaseComponent<{}, WidgetShellComponentState> {
  public lineHeight: any;
  public fontSize: any;
  public fontFamily: any;
  public colors: any;
  public state = {
    expanded: false,
    expandedSubmenu: undefined,
    targetedItem: undefined,
    closedBecauseReset: false,
  };

  public hostEl: any;
  public submenuEls: SubmenuComponent[] = [];

  constructor() {
    super();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  public componentDidMount(): void {
    // Subscribe on click
    document.addEventListener('click', this.handleOutsideClick, false);

    // Handles arrow up and down press
    this.hostEl.addEventListener('keydown', (event: any) => {
      const settings = ConfigurationService.singleton().config.settings;
      // TODO: Remove all usages of non-undefined type force-unwrapping using "!" as soon as TSLint stops being stupid!
      event.stopPropagation();
      event.preventDefault();
      const submenuStructure: Record<SettingKey, any> = {
        power: 0,
        fontSize: 3,
        fontFamily: ConfigurationService.singleton().config.settings.fontFamily.value.values.length + 1,
        fontWeight: 3,
        lineSpacing: 3,
        lineHeight: 3,
        colors: ConfigurationService.singleton().config.settings.colors.value.values.length + 1,
        linkHighlight: 0,
      };
      let submenuKeys = Object.keys(submenuStructure).filter(key => {
        if (key !== 'power') {
          return settings[key as keyof SettingsConfiguration]?.enable === true;
        } else {
          return settings.globalEnable.enable === true;
        }
      }) as SettingKey[];

      if (ConfigurationService.singleton().config.presentation.position.y === 'top') {
        submenuKeys = submenuKeys.filter(key => {
          if (key !== undefined && key != 'power') {
            return key;
          }
        }) as SettingKey[];

        submenuKeys.push('power');
      }

      const expandedIndex = this.state.expandedSubmenu ? submenuKeys.indexOf(this.state.expandedSubmenu!) : undefined;

      // Handle submenu selection

      if (['ArrowUp'].includes(event.key)) {
        let nextIndex = expandedIndex !== undefined ? (submenuKeys.length + expandedIndex - 1) % submenuKeys.length : submenuKeys.length - 1;
        while (this.submenuEls[nextIndex].props.disabled === true) {
          nextIndex = expandedIndex !== undefined ? (submenuKeys.length + nextIndex + 1) % submenuKeys.length : 0;
        }
        this.setState({ expandedSubmenu: submenuKeys[nextIndex], targetedItem: undefined, closedBecauseReset: false });
      } else if (['ArrowDown'].includes(event.key)) {
        let nextIndex = expandedIndex !== undefined ? (submenuKeys.length + expandedIndex + 1) % submenuKeys.length : 0;
        while (this.submenuEls[nextIndex].props.disabled === true) {
          nextIndex = expandedIndex !== undefined ? (submenuKeys.length + nextIndex + 1) % submenuKeys.length : 0;
        }
        this.setState({ expandedSubmenu: submenuKeys[nextIndex], targetedItem: undefined, closedBecauseReset: false });
      }
      // Handle item selection
      else if (this.state.expandedSubmenu && ['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        const itemsCount = submenuStructure[this.state.expandedSubmenu!];
        if (event.key === 'ArrowLeft') {
          const nextTargetedItemIndex =
            this.state.targetedItem !== undefined
              ? (itemsCount + this.state.targetedItem! - 1) % itemsCount
              : itemsCount <= ConfigurationService.singleton().config.presentation.buttonsPerRow
              ? itemsCount - 1
              : ConfigurationService.singleton().config.presentation.buttonsPerRow;

          this.setState({ targetedItem: nextTargetedItemIndex });
        } else if (event.key === 'ArrowRight') {
          const nextTargetedItemIndex = this.state.targetedItem !== undefined ? (itemsCount + this.state.targetedItem! + 1) % itemsCount : 0;
          this.setState({ targetedItem: nextTargetedItemIndex });
        }
      } else if (expandedIndex !== undefined && this.state.expandedSubmenu === 'power' && ['Enter', ' '].includes(event.key)) {
        this.submenuEls[expandedIndex].props.onClick();
      } else if (expandedIndex !== undefined && this.state.expandedSubmenu === 'linkHighlight' && ['Enter', ' '].includes(event.key)) {
        this.submenuEls[expandedIndex].props.onClick();
      }
      // Handle item click
      else if (expandedIndex !== undefined && this.state.targetedItem !== undefined && ['Enter', ' '].includes(event.key)) {
        this.submenuEls[expandedIndex].invokeClickOnChild(this.state.targetedItem!);
      }
      // Collapse widget
      else if (['Escape'].includes(event.key)) {
        this.hostEl.blur();
      }
    });
  }

  /**
   * Collapse widget on outside click
   * @param e Event
   */
  private handleOutsideClick(e: any): void {
    // Check if component rendered
    if (!this.hostEl) {
      return;
    }
    // Ignore clicks on the component itself
    if (this.hostEl.contains(e.target)) {
      return;
    }
  }

  /**
   *  Handles click on submenu
   * @param id - Submenu id
   */
  private handleSubmenuClick(id: SettingKey) {
    this.setState({
      expandedSubmenu: id,
      targetedItem: undefined,
      closedBecauseReset: this.state.expandedSubmenu !== id ? false : !this.state.closedBecauseReset,
    });
    // TODO: Reselect active value if active value for fontFamily or colors is set
  }

  /**
   * Render setting submenu if enabled
   * @param enabled - setting enabled property
   * @param child -  Child element
   */
  private _renderIfEnabled(enabled: boolean, child: preact.JSX.Element) {
    if (enabled) {
      return child;
    }
  }

  /**
   * Render vertically if position matches config position
   * @param y Top or bottom position
   * @param child Child element
   */
  private _renderVerticallyIfPosition(y: 'top' | 'bottom', child: preact.JSX.Element) {
    const uiConfig = ConfigurationService.singleton().config.presentation;
    if (uiConfig.position.y === y && globalEnabledAction.enabled) {
      return child;
    }
  }

  private _indexByPosition(index: number) {
    const uiConfig = ConfigurationService.singleton().config.presentation;
    if (uiConfig.position.x === 'right') {
      // If left aligned, keep indexes unchanged
      return index;
    } else {
      // If right aligned, move indexes by one to account for missing initial reset button
      return index - 1;
    }
  }

  /**
   * Resetting border
   * @param index Targeted item index
   */
  private _borderResetCondition(index: number): boolean {
    return this.state.targetedItem === this._indexByPosition(index);
  }

  public resetLanguage() {
    this.setState({});
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public render() {
    this.submenuEls = [];
    const language = ControllerService.singleton().lang;
    const uiConfig = ConfigurationService.singleton().config.presentation;
    const settingsConfig = ConfigurationService.singleton().config.settings;
    return (
      <div>
        <div
          tabIndex={uiConfig.tabIndex}
          class={` __omowidget__host ${uiConfig.position.y} ${uiConfig.position.x}`}
          ref={(node: any): void => {
            this.hostEl = node;
          }}>
          {/* Injected CSS */}
          <style>
            /**/
            {widgetGlobalCssSyntax}
            /**/
            {widgetMainCssSyntax}
            /**/
            {widgetSubmenuCssSyntax}
            /**/
            {widgetItemCssSyntax}
            /**/
            {messageComponentCssSyntax}
            /**/
          </style>

          {/* Main button */}
          <div class={`__omowidget__host__main_button ${uiConfig.position.y}`} onClick={(): void => {}}>
            <MenuItemComponent isMainIcon={true} tothem={true}>
              <div
                className="__omowidget_host_collapsable"
                onClick={event => {
                  event.stopPropagation();
                  this.hostEl.blur();
                }}></div>

              {
                <i
                  class={`__omowidget__host__main_button_image_on ${isAnySettingActive() ? 'active' : ''} tothem`}
                  dangerouslySetInnerHTML={{
                    __html: svgIconOn,
                  }}
                />
              }
              {
                <i
                  class={`__omowidget__host__main_button_image_off ${isAnySettingActive() ? 'active' : ''} tothem`}
                  dangerouslySetInnerHTML={{
                    __html: svgIconOff,
                  }}
                />
              }
            </MenuItemComponent>
          </div>

          {/* Menu */}
          <div class={`__omowidget__host__main_menu  ${uiConfig.position.y}`}>
            {/* Power buttom */}
            {this._renderVerticallyIfPosition(
              'bottom',
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'power'}
                title={ConfigurationService.singleton().config.lang[language].power || ''}
                colorFill={globalEnabledAction.currentValue === true && isAnySettingActive() === true}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'power'}
                disabled={isAnySettingActive() === false}
                onClick={() => {
                  if (isAnySettingActive() === false) {
                    return;
                  }
                  globalEnabledAction.setCurrentValue(!globalEnabledAction.currentValue);
                  this.setState({});
                }}
                mainIcon={svgIconPower}></SubmenuComponent>,
            )}

            {/* Font-size submenu */}
            {this._renderIfEnabled(
              fontSizeAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'fontSize'}
                title={ConfigurationService.singleton().config.lang[language].fontSize || ''}
                onReset={() => {
                  fontSizeAction.reset();
                  this.setState({ closedBecauseReset: true });
                }}
                borderReset={this._borderResetCondition(ConfigurationService.singleton().config.presentation.position.x === 'right' ? 0 : 3)}
                disabled={globalEnabledAction.currentValue === false}
                mainIcon={svgIconFontSize}
                colorFill={fontSizeAction.currentValue !== fontSizeAction.initialValue}
                displayValue={fontSizeAction.currentValue as number | undefined}
                displayValueMin={fontSizeAction.config.value.min}
                displayValueMax={fontSizeAction.config.value.max}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'fontSize' && globalEnabledAction.currentValue === true && this.state.closedBecauseReset === false}
                onClick={() => {
                  this.handleSubmenuClick('fontSize');
                }}>
                <MenuItemComponent
                  colorBorder={this.state.targetedItem === this._indexByPosition(1) && fontSizeAction.currentValue != fontSizeAction.config.value.max}
                  colorDisabled={this.state.targetedItem === this._indexByPosition(1) && fontSizeAction.currentValue === fontSizeAction.config.value.max}
                  disabled={fontSizeAction.currentValue! === fontSizeAction.config.value.max}
                  onClick={() => {
                    fontSizeAction.setCurrentValue(true);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconPlus,
                    }}
                  />
                </MenuItemComponent>
                <MenuItemComponent
                  colorBorder={this.state.targetedItem === this._indexByPosition(2) && fontSizeAction.currentValue !== fontSizeAction.config.value.min}
                  colorDisabled={this.state.targetedItem === this._indexByPosition(2) && fontSizeAction.currentValue === fontSizeAction.config.value.min}
                  disabled={fontSizeAction.currentValue === fontSizeAction.config.value.min}
                  onClick={() => {
                    fontSizeAction.setCurrentValue(false);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconMinus,
                    }}
                  />
                </MenuItemComponent>
              </SubmenuComponent>,
            )}

            {/* Font family submenu */}
            {this._renderIfEnabled(
              fontFamilyAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'fontFamily'}
                title={ConfigurationService.singleton().config.lang[language].fontFamily || ''}
                disabled={globalEnabledAction.currentValue === false}
                mainIcon={svgIconFontFamily}
                colorFill={fontFamilyAction.currentValue?.value !== fontFamilyAction.initialValue.value}
                onReset={() => {
                  fontFamilyAction.reset();
                  this.setState({ closedBecauseReset: true });
                }}
                borderReset={this._borderResetCondition(
                  ConfigurationService.singleton().config.presentation.position.x === 'right' ? 0 : settingsConfig.fontFamily.value.values.length + 1,
                )}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'fontFamily' && globalEnabledAction.currentValue === true && this.state.closedBecauseReset === false}
                onClick={() => {
                  this.handleSubmenuClick('fontFamily');
                }}>
                {Object.values(settingsConfig.fontFamily.value.values).map(
                  ({ abbreviation, name, value, fontSizeMultiplier, lineHeightMultiplier, letterSpacingMultiplier, wordSpacingMultiplier }, index) => {
                    return (
                      <MenuItemComponent
                        colorBorder={this.state.targetedItem === this._indexByPosition(1 + index)}
                        colorFill={fontFamilyAction.currentValue?.value === value}
                        onClick={() => {
                          fontFamilyAction.setCurrentValue({
                            abbreviation,
                            name,
                            value,
                            fontSizeFactor: fontSizeMultiplier,
                            lineHeightFactor: lineHeightMultiplier,
                            letterSpacingFactor: letterSpacingMultiplier,
                            wordSpacingFactor: wordSpacingMultiplier,
                          });
                          this.setState({});
                        }}>
                        {abbreviation === 'OB' && (
                          <i
                            dangerouslySetInnerHTML={{
                              __html: omoTypeB,
                            }}
                          />
                        )}
                        {abbreviation === 'OD' && (
                          <i
                            dangerouslySetInnerHTML={{
                              __html: omoTypeD,
                            }}
                          />
                        )}
                        {abbreviation !== 'OB' && abbreviation !== 'OD' && (
                          <span class={`__omowidget__host__font_family_abbreviation ${abbreviation}`} title={name}>
                            <span class={`__omowidget__host__font_family_abbreviation_first ${abbreviation}`}>{abbreviation[0]}</span>
                            <span class={`__omowidget__host__font_family_abbreviation_second ${abbreviation}`}>{abbreviation[1]}</span>
                          </span>
                        )}
                      </MenuItemComponent>
                    );
                  },
                )}
              </SubmenuComponent>,
            )}

            {/* Font weight submenu */}
            {this._renderIfEnabled(
              fontWeightAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'fontWeight'}
                title={ConfigurationService.singleton().config.lang[language].fontWeight || ''}
                mainIcon={svgIconFontWeight}
                colorFill={fontWeightAction.currentValue?.value !== fontWeightAction.initialValue.value}
                disabled={globalEnabledAction.currentValue === false}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'fontWeight' && globalEnabledAction.currentValue === true && this.state.closedBecauseReset === false}
                borderReset={this._borderResetCondition(
                  ConfigurationService.singleton().config.presentation.position.x === 'right' ? 0 : settingsConfig.fontWeight.value.values.length + 1,
                )}
                onReset={() => {
                  fontWeightAction.reset();
                  this.setState({ closedBecauseReset: true });
                }}
                onClick={() => {
                  this.handleSubmenuClick('fontWeight');
                }}>
                <MenuItemComponent
                  colorBorder={
                    this.state.targetedItem === this._indexByPosition(1) &&
                    fontWeightAction.currentValue?.value != fontWeightAction.configuredValues[fontWeightAction.configuredValues.length - 1].value
                  }
                  colorDisabled={
                    this.state.targetedItem === this._indexByPosition(1) &&
                    fontWeightAction.currentValue?.value === fontWeightAction.configuredValues[fontWeightAction.configuredValues.length - 1].value
                  }
                  disabled={fontWeightAction.currentValue?.value === fontWeightAction.configuredValues[fontWeightAction.configuredValues.length - 1].value}
                  onClick={() => {
                    fontWeightAction.setCurrentValue(true);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconPlus,
                    }}
                  />
                </MenuItemComponent>
                <MenuItemComponent
                  colorBorder={
                    this.state.targetedItem === this._indexByPosition(2) && fontWeightAction.currentValue?.value !== fontWeightAction.configuredValues[0].value
                  }
                  colorDisabled={
                    this.state.targetedItem === this._indexByPosition(2) && fontWeightAction.currentValue?.value === fontWeightAction.configuredValues[0].value
                  }
                  disabled={fontWeightAction.currentValue?.value === fontWeightAction.configuredValues[0].value}
                  onClick={() => {
                    fontWeightAction.setCurrentValue(false);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconMinus,
                    }}
                  />
                </MenuItemComponent>
              </SubmenuComponent>,
            )}

            {/* Line spacing submenu*/}
            {this._renderIfEnabled(
              lineSpacingAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'lineSpacing'}
                disabled={globalEnabledAction.currentValue === false}
                title={ConfigurationService.singleton().config.lang[language].lineSpacing || ''}
                mainIcon={svgIconSpacing}
                colorFill={lineSpacingAction.currentValue !== lineSpacingAction.initialValue}
                displayValue={lineSpacingAction.currentValue}
                displayValueMin={lineSpacingAction.config.value.min}
                displayValueMax={lineSpacingAction.config.value.max}
                borderReset={this._borderResetCondition(ConfigurationService.singleton().config.presentation.position.x === 'right' ? 0 : 3)}
                onReset={() => {
                  lineSpacingAction.reset();
                  this.setState({ closedBecauseReset: true });
                }}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'lineSpacing' && globalEnabledAction.currentValue === true && this.state.closedBecauseReset === false}
                onClick={() => {
                  this.handleSubmenuClick('lineSpacing');
                }}>
                <MenuItemComponent
                  colorBorder={this.state.targetedItem === this._indexByPosition(1) && lineSpacingAction.currentValue !== lineSpacingAction.config.value.max}
                  colorDisabled={this.state.targetedItem === this._indexByPosition(1) && lineSpacingAction.currentValue === lineSpacingAction.config.value.max}
                  disabled={lineSpacingAction.currentValue === lineSpacingAction.config.value.max}
                  onClick={() => {
                    lineSpacingAction.setCurrentValue(true);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconPlus,
                    }}
                  />
                </MenuItemComponent>
                <MenuItemComponent
                  colorBorder={this.state.targetedItem === this._indexByPosition(2) && lineSpacingAction.currentValue !== lineSpacingAction.config.value.min}
                  colorDisabled={this.state.targetedItem === this._indexByPosition(2) && lineSpacingAction.currentValue === lineSpacingAction.config.value.min}
                  disabled={lineSpacingAction.currentValue === lineSpacingAction.config.value.min}
                  onClick={() => {
                    lineSpacingAction.setCurrentValue(false);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconMinus,
                    }}
                  />
                </MenuItemComponent>
              </SubmenuComponent>,
            )}

            {/* Line height submenu */}
            {this._renderIfEnabled(
              lineHeightAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'lineHeight'}
                disabled={globalEnabledAction.currentValue === false}
                title={ConfigurationService.singleton().config.lang[language].lineHeight || ''}
                mainIcon={svgIconLineHeight}
                colorFill={lineHeightAction.currentValue !== lineHeightAction.initialValue}
                onReset={() => {
                  lineHeightAction.reset();
                  this.setState({ closedBecauseReset: true });
                }}
                borderReset={this._borderResetCondition(ConfigurationService.singleton().config.presentation.position.x === 'right' ? 0 : 3)}
                displayValue={lineHeightAction.currentValue}
                displayValueMin={lineHeightAction.config.value.min}
                displayValueMax={lineHeightAction.config.value.max}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'lineHeight' && globalEnabledAction.currentValue === true && this.state.closedBecauseReset === false}
                onClick={() => {
                  this.handleSubmenuClick('lineHeight');
                }}>
                <MenuItemComponent
                  colorBorder={this.state.targetedItem === this._indexByPosition(1) && lineHeightAction.currentValue !== lineHeightAction.config.value.max}
                  colorDisabled={this.state.targetedItem === this._indexByPosition(1) && lineHeightAction.currentValue === lineHeightAction.config.value.max}
                  disabled={lineHeightAction.currentValue === lineHeightAction.config.value.max}
                  onClick={() => {
                    lineHeightAction.setCurrentValue(true);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconPlus,
                    }}
                  />
                </MenuItemComponent>
                <MenuItemComponent
                  colorBorder={this.state.targetedItem === this._indexByPosition(2) && lineHeightAction.currentValue !== lineHeightAction.config.value.min}
                  colorDisabled={this.state.targetedItem === this._indexByPosition(2) && lineHeightAction.currentValue === lineHeightAction.config.value.min}
                  disabled={lineHeightAction.currentValue === lineHeightAction.config.value.min}
                  onClick={() => {
                    lineHeightAction.setCurrentValue(false);
                    this.setState({});
                  }}>
                  <i
                    dangerouslySetInnerHTML={{
                      __html: svgIconMinus,
                    }}
                  />
                </MenuItemComponent>
              </SubmenuComponent>,
            )}

            {/* Colors submenu */}
            {this._renderIfEnabled(
              colorsAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'colors'}
                title={ConfigurationService.singleton().config.lang[language].colors || ''}
                disabled={globalEnabledAction.currentValue === false}
                borderReset={this._borderResetCondition(
                  ConfigurationService.singleton().config.presentation.position.x === 'right' ? 0 : settingsConfig.colors.value.values.length + 1,
                )}
                onReset={() => {
                  colorsAction.reset();
                  this.setState({ closedBecauseReset: true });
                }}
                colorFill={
                  colorsAction.currentValue?.background !== colorsAction.initialValue.background ||
                  colorsAction.currentValue?.foreground !== colorsAction.initialValue.foreground
                }
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'colors' && globalEnabledAction.currentValue === true && this.state.closedBecauseReset === false}
                onClick={() => {
                  this.handleSubmenuClick('colors');
                }}>
                {Object.values(settingsConfig.colors.value.values).map(({ name, background, foreground, filter, icon }, index) => {
                  return (
                    <MenuItemComponent
                      colorBorder={this.state.targetedItem === this._indexByPosition(index + 1)}
                      bgColor={icon ? '' : background}
                      colorShadow={
                        colorsAction.currentValue?.name === name &&
                        colorsAction.currentValue?.background === background &&
                        colorsAction.currentValue?.foreground === foreground
                      }
                      onClick={() => {
                        colorsAction.setCurrentValue({ name, background, foreground, filter });
                        this.setState({});
                      }}>
                      {icon && !icon.startsWith('http') && (
                        <i
                          dangerouslySetInnerHTML={{
                            __html: icon,
                          }}
                        />
                      )}
                      {icon && icon.startsWith('http') && <img class="__omowidget__icon" src={icon} />}
                    </MenuItemComponent>
                  );
                })}
              </SubmenuComponent>,
            )}

            {/* Link highlight submenu */}
            {this._renderIfEnabled(
              linkHighlightAction.enabled as boolean,
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'linkHighlight'}
                title={ConfigurationService.singleton().config.lang[language].linkHighlight || ''}
                colorFill={linkHighlightAction.currentValue === true}
                parentExpanded={this.state.expanded}
                disabled={globalEnabledAction.currentValue === false}
                expanded={this.state.expandedSubmenu === 'linkHighlight' && globalEnabledAction.currentValue === true}
                onClick={() => {
                  linkHighlightAction.setCurrentValue(!linkHighlightAction.currentValue);
                  this.setState({});
                }}
                mainIcon={svgIconLinkHighlight}></SubmenuComponent>,
            )}

            {/* Power buttom */}
            {this._renderVerticallyIfPosition(
              'top',
              <SubmenuComponent
                ref={ref => ref && this.submenuEls.push(ref)}
                id={'power'}
                title="Uključi / isključi"
                colorFill={globalEnabledAction.currentValue === true && isAnySettingActive() === true}
                parentExpanded={this.state.expanded}
                expanded={this.state.expandedSubmenu === 'power'}
                disabled={isAnySettingActive() === false}
                onClick={() => {
                  globalEnabledAction.setCurrentValue(!globalEnabledAction.currentValue);
                  this.setState({});
                }}
                mainIcon={svgIconPower}></SubmenuComponent>,
            )}
          </div>
        </div>
        {/* Close icon  */}
        <div
          class={` __omowidget__host__close_button  ${uiConfig.position.y} ${uiConfig.position.x} ${
            ConfigurationService.singleton().config.presentation.close.showButton ? '' : '__omowidget__host__close_button_hidden'
          }`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (ConfigurationService.singleton().config.presentation.close.showButton) {
              UserSettingsStore.getSingletonInstance<WidgetUserSettings>().update((settings?: WidgetUserSettings) => {
                if (settings) {
                  settings.closed.value = true;
                }
              });
            }
          }}>
          <i
            dangerouslySetInnerHTML={{
              __html: svgIconClose,
            }}
          />
        </div>
      </div>
    );
  }
}
