import { composeCssSelector } from '../..';
import {
  FontWeightSetting,
  WidgetConfigurationStylingException,
  WidgetConfigurationStylingAddition,
  WidgetConfigurationStylesheetsDeclaration,
  SettingsConfiguration,
  WidgetUserSettings,
} from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { ControllerService } from '../../ControllerService';
import { BaseAction } from '../BaseAction';

/**
 * Font weight action implementation
 */
class FontWeightAction extends BaseAction<FontWeightSetting> {
  // #region Action description
  public configurationPropertyKeys: string[] = ['fontWeight'];
  public get config() {
    return ConfigurationService.singleton().config.settings.fontWeight;
  }

  public cssDeclarationNamesAndDefaultValues: Record<string, string | ((el: Element) => string)> = {
    'font-weight': 'initial',
  };

  public className: string[] = ['__omowidget__fontweight__'];
  // #endregion

  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.fontWeight.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return this.initialValue.value !== this.currentValue?.value;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): FontWeightSetting {
    return settings.fontWeight.value.initial;
  }

  public _getCurrentValue(settings: WidgetUserSettings): FontWeightSetting | undefined {
    return settings.fontWeight;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean): void {
    if (value) {
      this._increaseCurrentValue(settings);
    } else {
      this._decreaseCurrentValue(settings);
    }
  }
  private _decreaseCurrentValue(settings: WidgetUserSettings) {
    let index = this.configuredValues.findIndex(fontWeight => fontWeight.value === settings.fontWeight.value);
    if (index > 0) {
      settings.fontWeight.value = this.configuredValues[index - 1].value;
      settings.fontWeight.name = this.configuredValues[index - 1].name;
    } else {
      settings.fontWeight.value = this.configuredValues[0].value;
      settings.fontWeight.name = this.configuredValues[0].name;
    }
  }
  private _increaseCurrentValue(settings: WidgetUserSettings) {
    let index = this.configuredValues.findIndex(fontWeight => fontWeight.value === settings.fontWeight.value);
    if ((index !== -1 && index < this.configuredValues.length - 1) || index === -1) {
      settings.fontWeight.value = this.configuredValues[index + 1].value;
      settings.fontWeight.name = this.configuredValues[index + 1].name;
    } else {
      settings.fontWeight.value = this.configuredValues[this.configuredValues.length - 1].value;
      settings.fontWeight.name = this.configuredValues[this.configuredValues.length - 1].name;
    }
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.fontWeight.value = this.initialValue.value;
    settings.fontWeight.name = this.initialValue.name;
  }
  // #endregion

  // #region Generate action's value as CSS variable
  protected _generateVariablesCurrentSyntax(globallyEnabled: boolean): string | undefined {
    return globallyEnabled && this.currentValue?.value && this.enabled
      ? `--omowidget--settings--fontweight--value: ${this.currentValue?.value};`
      : '/* --omowidget--settings--fontweight--value = undefined */';
  }
  // #endregion

  // #region Generate action's exceptions' declarations
  public generateExceptionsSelectorsSyntax(exception: WidgetConfigurationStylingException, selector: string): string[] {
    if (exception.disable.fontWeight) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontweight__')];
    }
    return ['__omo__noelement__'];
  }

  public generateException(exception: WidgetConfigurationStylingException): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && exception.disable.fontWeight) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'font-weight',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, exception);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateException(exception: WidgetConfigurationStylingException): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(exception, 'fontWeight', exception.disable.fontWeight),
    );
  }
  // #endregion

  // #region Generate action's additions' declarations
  public generateAdditionSelectorsSyntax(addition: WidgetConfigurationStylingAddition, selector: string): string[] {
    if (addition.enable.fontWeight) {
      return [composeCssSelector(selector, '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontweight__')];
    }
    return ['__omo__noelement__'];
  }

  public generateAddition(addition: WidgetConfigurationStylingAddition): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };

    // Get CSS overriding syntax
    if (this.enabled && addition.enable.fontWeight) {
      const declaration: WidgetConfigurationStylesheetsDeclaration = {
        name: 'font-weight',
      };
      const overrideFF = this.overrideCssStyleDeclaration(declaration, addition);
      override.variables.push(...overrideFF.variables);
      override.declarations.push(...overrideFF.declarations);
    }

    // Return overrides
    return override;
  }

  public validateAddition(addition: WidgetConfigurationStylingAddition): void {
    ControllerService.singleton()._registerExceptionAndAdditionValidationCallback(() =>
      this._validateDeclaration(addition, 'fontWeight', addition.enable.fontWeight),
    );
  }
  // #endregion

  // #region Override CSS declaration
  public overrideCssStyleDeclaration(
    declaration: WidgetConfigurationStylesheetsDeclaration,
    exceptionOrAdditionConfiguration?: WidgetConfigurationStylingException | WidgetConfigurationStylingAddition,
  ): { variables: string[]; declarations: string[] } {
    // Initialize overriding syntax
    const override: { variables: string[]; declarations: string[] } = { variables: [], declarations: [] };
    // Get cast exception/addition
    const exception = exceptionOrAdditionConfiguration as WidgetConfigurationStylingException;
    const addition = exceptionOrAdditionConfiguration as WidgetConfigurationStylingAddition;

    // Check if important
    const isImportant = declaration.value && declaration.value.toLowerCase().indexOf('!important') !== -1;
    const importantSyntax = isImportant ? ' !important' : '';
    if (declaration.value && isImportant) {
      declaration.value = declaration.value.replace(/(\s)?!important/i, '');
    }

    // Set original value if provided by host page CSS
    if (declaration.value) {
      override.variables.push(`--omowidget--css--fontweight--original--value: ${this.parseFontWeight(declaration.value)};`);
    }
    // If exception, unset setting variable to propagate to children
    if (exception?.disable) {
      override.variables.push(`--omowidget--settings--fontweight--value: initial;`);
    }
    // Set CSS property value (Set user setting, or fallback to original CSS declaration or closest parent's original CSS declaration)
    if (declaration.value === undefined || !isNaN(parseFloat(declaration.value)) || ['bold', 'normal'].includes(declaration.value)) {
      // Compose full CSS value (with units) from user setting and value the composed value to only be larger than the original value
      // (or invalid variable if no setting)
      const calculatedFontWeightValue = `var(--omowidget--settings--fontweight--value)`;
      const calculatedSyntax =
        declaration.value === undefined ? calculatedFontWeightValue : `max(var(--omowidget--css--fontweight--original--value), ${calculatedFontWeightValue})`;
      override.variables.push(`--omowidget--css--fontweight--calculated--prevalue: ${calculatedSyntax};`);

      // Use composed full CSS value if defined, or fallback to original CSS declaration or closest parent's original CSS declaration)
      override.variables.push(
        `--omowidget--css--fontweight--calculated--value: var(--omowidget--css--fontweight--calculated--prevalue, var(--omowidget--css--fontweight--original--value));`,
      );
    }

    // If original CSS value is not scalable leave as is
    else {
      override.variables.push(`/* Font-Weight: Unscalable: */`);
      override.variables.push(`--omowidget--css--fontweight--calculated--value: var(--omowidget--css--fontweight--original--value);`);
    }

    // If not exception/addition, set calculated value as declaration syntax
    if (!exception && !addition) {
      override.declarations.push(`${declaration.name}: var(--omowidget--css--fontweight--calculated--value) ${importantSyntax};`);
    }
    // If explicit value set for exception/addition, set declaration syntax
    else {
      // Explicit value exception
      if (typeof exception?.disable?.fontWeight === 'string') {
        override.declarations.push(`${declaration.name}: ${exception?.disable.fontWeight as string} ${importantSyntax};`);
      }
      // Explicit value addition
      if (typeof addition?.enable?.fontWeight === 'string') {
        override.variables.push(`--omowidget--css--fontweight--original--value: ${addition?.enable?.fontWeight};`);
        override.declarations.push(`${declaration.name}: var(--omowidget--css--fontweight--calculated--value) ${importantSyntax};`);
      }
      // Non-explicit value addition
      else if (addition?.enable?.fontWeight) {
        override.declarations.push(`${declaration.name}: var(--omowidget--css--fontweight--calculated--value) ${importantSyntax};`);
      }
    }

    // Return overrides
    return override;
  }
  // #endregion

  // #region Reflect state onto root element
  public generateStyleAttributeOverrideSyntax(): string | undefined {
    return `html.__omowidget__fontweight__ [style*="font:"], html.__omowidget__fontweight__ [style*="font-weight:"] {\n${[
      this.overrideCssStyleDeclaration({ name: 'font-weight', value: 'normal !important' }),
    ].join('\n')}\n}`;
  }

  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (this.active) {
        document.getElementsByTagName('html')[0].classList.add('__omowidget__fontweight__');
      } else {
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__fontweight__');
      }
      document.getElementsByTagName('html')[0].setAttribute('omowidget-fontweight', this.currentValue?.value?.toString() || '');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__fontweight__');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-fontweight');
    }
  }
  // #endregion

  /**
   * Returns sorted configured font-weight values
   */
  public get configuredValues() {
    ConfigurationService.singleton().config.settings.fontWeight.value.values.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
    return ConfigurationService.singleton().config.settings.fontWeight.value.values;
  }

  /**
   * Parses string font weights into numeric
   * @param value
   */
  private parseFontWeight(value: string) {
    return value === 'bold' ? '700' : value === 'normal' ? '400' : value;
  }
}

// Export
export const fontWeightAction = new FontWeightAction();
