// Import dependencies
import { h } from 'preact';
import { WidgetUserSettings } from '../../../../common/types';
import { actions } from '../actions';
import { colorsAction } from '../actions/Colors';
import { fontWeightAction } from '../actions/FontWeight';
import { globalEnabledAction } from '../actions/GlobalEnabled';

import { linkHighlightAction } from '../actions/LinkHighlight';
import { ConfigurationService } from '../ConfigurationService';

/**
 * Provides functionality for overriding host page's style with widget configured settings
 */
export class StyleOverrideService {
  // #region Singleton
  /**
   * Holds singleton instance of the service
   */
  private static _service?: StyleOverrideService;
  /**
   * Gets singleton instance of the service
   * @returns Singleton instance of the service
   */
  public static singleton() {
    return StyleOverrideService._service || (StyleOverrideService._service = new StyleOverrideService());
  }
  // #endregion

  // #region Initialization
  /**
   * Host script element
   */
  private _scriptEl?: HTMLOrSVGScriptElement;
  /**
   * Holds reference to style element hosting base-level styles
   */
  private _fallbackStyleHostEl?: HTMLStyleElement;
  /**
   * Holds reference to style element hosting override style
   */
  private _overrideStyleHostEl?: HTMLStyleElement;
  /**
   * Holds reference to style element hosting styles for configured exceptions
   */
  private _cssExceptionsStyleEl?: HTMLStyleElement;
  /**
   * Holds reference to style element hosting styles for configured additions
   */
  private _cssAdditionsStyleEl?: HTMLStyleElement;
  /**
   * Holds reference to style element hosting CSS styling injected via configuration
   */
  private _injectedCSSStyleEl?: HTMLStyleElement;
  /**
   * Holds reference to style element hosting CSS styling for link highlighting injected via configuration
   */
  private _linkHighlightElement?: HTMLStyleElement;
  /**
   * Holds reference to style element hosting CSS "rem" unit mitigation
   */
  private _remMitigationCSSStyleEl?: HTMLStyleElement;

  /**
   * Holds reference to style element hosting css variables
   */
  private _overrideVariablesHostEl?: HTMLStyleElement;
  /**
   * Holds reference to link element loading custom font-faces configuration
   */
  private _fontFamilyLinkElement?: HTMLLinkElement;

  /**
   * Initializes override styling by injecting required overriding CSS syntax into a style element added to host page
   * @param Host script element
   */
  public async initialize(scriptEl: HTMLOrSVGScriptElement) {
    let styleElements = [];
    // Store reference to script element
    this._scriptEl = scriptEl;
    const config = ConfigurationService.singleton().config;

    // Mark parent page as omoguru widget embedded page
    document.getElementsByTagName('html')[0].setAttribute('__omowidget__', '');

    // Inject generated, base-level CSS syntax
    if (!this._fallbackStyleHostEl) {
      this._fallbackStyleHostEl = document.createElement('style');
      this._fallbackStyleHostEl.setAttribute('__omowidget__fallbackstyle__', '');
    }
    this._fallbackStyleHostEl.innerHTML = this._generateFallbackCssSyntax();

    // Inject overridden CSS syntax
    if (!this._overrideStyleHostEl) {
      this._overrideStyleHostEl = document.createElement('style');
      this._overrideStyleHostEl.setAttribute('__omowidget__overridestyle__', '');
    }
    this._overrideStyleHostEl.innerHTML = [this._generateOverridingCssSyntax(), this._generateFilterSyntax(), this._generateInitialFontWeightOverride()].join(
      '\n',
    );

    // Inject link highlighting CSS syntax
    if (!this._linkHighlightElement && config.resources.linkHighlight && linkHighlightAction.enabled) {
      this._linkHighlightElement = document.createElement('style');
      this._linkHighlightElement.setAttribute('__omowidget__links__enabled__', '');
      this._linkHighlightElement.innerHTML = config.resources.linkHighlight;
    }

    // Inject "rem" unit mitication CSS syntax
    const remMitigationSyntax = this._generateRemMitigationCssSyntax();
    if (!this._remMitigationCSSStyleEl && remMitigationSyntax.length) {
      this._remMitigationCSSStyleEl = document.createElement('style');
      this._remMitigationCSSStyleEl.setAttribute('__omowidget__rem_mitigation__', '');
      this._remMitigationCSSStyleEl.innerHTML = remMitigationSyntax.join('\n');
    }

    // Inject exceptions' CSS syntax
    if (config.stylesheets.exceptions) {
      if (!this._cssExceptionsStyleEl) {
        this._cssExceptionsStyleEl = document.createElement('style');
        this._cssExceptionsStyleEl.setAttribute('__omowidget__exceptions', '');
      }
      this._cssExceptionsStyleEl.innerHTML = this._generateStylingExceptionsSyntax().join('\n');
    }
    // Inject  additions' CSS syntax
    if (config.stylesheets.additions) {
      if (!this._cssAdditionsStyleEl) {
        this._cssAdditionsStyleEl = document.createElement('style');
        this._cssAdditionsStyleEl.setAttribute('__omowidget__additions', '');
      }
      this._cssAdditionsStyleEl.innerHTML = this._generateStylingAdditionsSyntax().join('\n');
    }

    // If required, inject CSS syntax from configuration
    if (config.resources.injectCssSyntax) {
      if (!this._injectedCSSStyleEl) {
        this._injectedCSSStyleEl = document.createElement('style');
        this._injectedCSSStyleEl.setAttribute('__omowidget__injected__', '');
      }
      this._injectedCSSStyleEl.innerHTML = config.resources.injectCssSyntax;
    }

    // Inject fallback <style> element
    document.head.prepend(this._fallbackStyleHostEl);
    // Inject other <style /> elements
    [
      this._cssExceptionsStyleEl,
      this._cssAdditionsStyleEl,
      this._overrideStyleHostEl,
      ...(this._remMitigationCSSStyleEl ? [this._remMitigationCSSStyleEl] : []),
      this._linkHighlightElement,
      this._injectedCSSStyleEl,
    ]
      .reverse()
      .forEach(element => {
        if (element) this._scriptEl?.parentNode?.insertBefore(element, this._scriptEl.nextSibling);
      });
  }
  // #endregion

  // #region Initialization of non-standard actions
  /**
   * Adds CSS syntax required for BF filters to work
   */
  private _generateFilterSyntax() {
    if (colorsAction.enabled) {
      return `html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__filter__ {
        filter: var(--omowidget--settings--filter--value);
      }\n`;
    }
  }

  /**
   * Sets an explicit global default for font-weight
   */
  private _generateInitialFontWeightOverride() {
    if (fontWeightAction.enabled) {
      return `html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontweight__ b, html.__omowidget__enabled.__omowidget__active__.__omowidget__fontweight__ strong {
        font-weight: max(var(--omowidget--settings--fontweight--value), 700);
      }\n`;
    }
  }
  // #endregion

  // #region Settings update
  /**
   * Updates override styling by setting CSS variables
   * @params settings User settings
   */
  public async update(settings: WidgetUserSettings) {
    // Generate host style element
    if (!this._overrideVariablesHostEl) {
      this._overrideVariablesHostEl = document.createElement('style');
      this._overrideVariablesHostEl.setAttribute('__omowidget__variables__', '');
      if (this._scriptEl?.parentNode) {
        this._scriptEl.parentNode.insertBefore(this._overrideVariablesHostEl, this._scriptEl.nextSibling);
      } else {
        document.head.appendChild(this._overrideVariablesHostEl);
      }
    }

    // Inject font-faces CSS link
    if (!this._fontFamilyLinkElement && ConfigurationService.singleton().config.resources.cssFontFaces !== undefined) {
      this._fontFamilyLinkElement = document.createElement('link');
      this._fontFamilyLinkElement.setAttribute('__omowidget__fonts__', '');
      this._fontFamilyLinkElement.setAttribute('rel', 'stylesheet');
      this._fontFamilyLinkElement.setAttribute('type', 'text/css');
      this._fontFamilyLinkElement.setAttribute('href', ConfigurationService.singleton().config.resources.cssFontFaces!);
      if (this._scriptEl?.parentNode) {
        this._scriptEl.parentNode.insertBefore(this._fontFamilyLinkElement, this._scriptEl.nextSibling);
      } else {
        document.head.appendChild(this._fontFamilyLinkElement);
      }
    }

    // Generate CSS variables syntax
    this._overrideVariablesHostEl.innerHTML = [
      // Generate config CSS variables' syntax
      this._generateConfigurationCssVariables(),
      // Generate user settings CSS variables' syntax
      this._generateOverriddingCssVariables(settings),
    ].join('\n\n');
  }
  // #endregion

  // #region CSS syntax: Fallbacks
  /**
   * Composes fallback CSS syntax
   */
  private _generateFallbackCssSyntax() {
    // Define html styling
    const fallbackSyntax = [
      // Prevent widget icon and menu from showing on print
      `@media print { [__omowidget__] [__omowidget__host__] { display: none !important; } }`,
      // Make actions only apply when widget is active
      ...actions.map(action => {
        const composedSyntax = [];
        if (Object.keys(action.cssDeclarationNamesAndDefaultValues).length !== 0) {
          for (let declarationName of Object.keys(action.cssDeclarationNamesAndDefaultValues)) {
            const value = action.cssDeclarationNamesAndDefaultValues[declarationName];
            const override = action.overrideCssStyleDeclaration({
              name: declarationName,
              value: typeof value === 'function' ? value(document.querySelector('html') as Element) : value,
            });
            const selector = `html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.${
              // !FIXME: Why?!?!
              declarationName == 'color' ? action.className[1] : action.className[0]
            }`;
            composedSyntax.push(
              composeCssBlock('', [selector], ['--OMO-COMMENT: Omoguru FALLBACK definition;', ...override.variables, ...override.declarations]),
            );
          }
        }

        return composedSyntax.join('');
      }),
    ].join('\n');

    // Define override syntax for elements using [style] attributes for styling
    const styleAttributeOverrideSyntax = actions
      .map(action => {
        return action.generateStyleAttributeOverrideSyntax();
      })
      .join('\n');
    // Return styles
    return [fallbackSyntax, styleAttributeOverrideSyntax].join('\n\n');
  }
  // #endregion

  // #region CSS syntax: Overrides for analyzed CSS
  /**
   * Composes overriding CSS syntax
   */
  private _generateOverridingCssSyntax() {
    // Get configuration
    const config = ConfigurationService.singleton().config;

    // Compose CSS syntax
    return (
      config.stylesheets.analysis
        // Compose all stylesheets
        .map(stylesheet => {
          return (
            stylesheet.queries
              // Compose all meta-queries (empty, or not)
              .map(mediaQuery => {
                const mediaQuerySyntax = mediaQuery.rules
                  // Collect (and process) all selectors
                  .map(rule => {
                    // Compose all declarations
                    const declarationsSyntax = rule.declarations.map(declaration => {
                      return actions
                        .map(action => {
                          const override = action.overrideCssStyleDeclaration(declaration);
                          return rule.selectors
                            .map(selector => {
                              let composed = '';
                              if (Object.keys(action.cssDeclarationNamesAndDefaultValues).includes(declaration.name)) {
                                // !FIXME: Why?!?!
                                if (declaration.name === 'color') {
                                  composed = composeCssSelector(
                                    selector,
                                    `.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.${action.className[1]}`,
                                  );
                                } else {
                                  composed = composeCssSelector(
                                    selector,
                                    `.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.${action.className[0]}`,
                                  );
                                  if (['font-size, line-height'].includes(declaration.name)) {
                                    composed = `${composed}, ${composeCssSelector(
                                      selector,
                                      `.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__.__omowidget__fontfamily__`,
                                    )}}}`;
                                  }
                                }
                                composed = composeCssBlock(
                                  '',
                                  [composed],
                                  ['--OMO-COMMENT: Omoguru OVERRIDE definition;', ...override.variables, ...override.declarations],
                                );
                              }
                              return composed;
                            })
                            .join('\n');
                        })
                        .join('');
                    });
                    // Compose declarations into a selector
                    return `${declarationsSyntax.join('\n')}\n`;
                  })
                  .join('\n');
                // Compose selectors into a media-query
                return mediaQuery.query
                  ? `@media ${mediaQuery.query} {\n${mediaQuerySyntax}\n}`
                  : `/* <No media query> */\n${mediaQuerySyntax}\n/* </No media query> */`;
              })
              // Compose media-queries into a stylesheet
              .join('\n\n')
          );
        })
        // Compose stylesheets
        .join('\n\n')
    );
  }
  // #endregion

  // #region CSS syntax: Exceptions
  /**
   * Generates styling exceptions syntax
   */
  private _generateStylingExceptionsSyntax() {
    // Get configuration
    const config = ConfigurationService.singleton().config;

    // Process exceptions
    return (config.stylesheets.exceptions || []).map((exception: any) => {
      if (exception.disable) {
        const composed = Object.keys(exception.disable || []).map(configurationPropertyKey => {
          // Compose exception selectors
          const selectors: string[] = exception.selectors
            .map((selector: string) => {
              return selector.split(',').map(selector => {
                return actions
                  .filter(action => action.configurationPropertyKeys.includes(configurationPropertyKey))
                  .map(action => action.generateExceptionsSelectorsSyntax(exception, selector))
                  .reduce((all: string[], selectors: string[]) => {
                    all.push(...selectors);
                    return all;
                  }, [])
                  .join(', ');
              });
            })
            .reduce((all: string[], selectors: string[]) => {
              all.push(...selectors);
              return all;
            }, []);
          // Compose exception declarations
          const declarations: string[] = actions
            .filter(action => action.configurationPropertyKeys.includes(configurationPropertyKey))
            .map(action => {
              action.validateException(exception);
              return action;
            })
            .map(action => action.generateException(exception))
            .reduce((all: string[], override) => {
              all.push(...override.variables);
              all.push(...override.declarations);
              return all;
            }, []);
          // Return exception CSS block syntax
          return selectors.length && declarations.length
            ? composeCssBlock('', selectors, ['--OMO-COMMENT: Omoguru EXCEPTION definition;', ...declarations])
            : '';
        });
        return composed.join('\n');
      }
    });
  }
  // #endregion

  // #region CSS syntax: Additions
  /**
   * Generates styling additions syntax
   */
  private _generateStylingAdditionsSyntax() {
    // Get configuration
    const config = ConfigurationService.singleton().config;

    // Process additions
    return (config.stylesheets.additions || []).map((addition: any) => {
      if (addition.enable) {
        const composed = Object.keys(addition.enable || []).map(configurationPropertyKey => {
          // Compose addition selectors
          const selectors: string[] = addition.selectors
            .map((selector: string) => {
              return selector
                .split(',')
                .map(selector => {
                  return actions
                    .filter(action => action.configurationPropertyKeys.includes(configurationPropertyKey))
                    .map(action => action.generateAdditionSelectorsSyntax(addition, selector))
                    .reduce((all: string[], selectors: string[]) => {
                      all.push(...selectors);
                      return all;
                    }, [])
                    .join(', ');
                })
                .filter(s => !!s);
            })
            .reduce((all: string[], selectors: string[]) => {
              all.push(...selectors);
              return all;
            }, []);
          // Compose addition declarations
          const declarations: string[] = actions
            .filter(action => action.configurationPropertyKeys.includes(configurationPropertyKey))
            .map(action => {
              action.validateAddition(addition);
              return action;
            })
            .map(action => action.generateAddition(addition))
            .reduce((all: string[], override) => {
              all.push(...override.variables);
              all.push(...override.declarations);
              return all;
            }, []);
          // Validate addition explicit values
          // TODO: ...
          // Return addition CSS block syntax
          return selectors.length && declarations.length
            ? composeCssBlock('', selectors, ['--OMO-COMMENT: Omoguru ADDITION definition;', ...declarations])
            : '';
        });
        return composed.join('\n');
      }
    });
  }
  // #endregion

  // #region CSS syntax: Settings as CSS variables
  /**
   * Composes configuration CSS variables
   */
  private _generateConfigurationCssVariables() {
    // Get configuration
    const config = ConfigurationService.singleton().config;
    const filteredSettings = actions.filter(key => {
      return key.enabled === true;
    });

    // Compose CSS variables' syntax
    const configVariablesSyntax = [
      `/* Widget configuration */`,
      `--omowidget--config--ui--zindex: ${config.presentation.zIndex <= 67108861 ? config.presentation.zIndex : 67108861};`,
      `--omowidget--config--ui--size: ${config.presentation.size};`,
      `--omowidget--config--ui--children-length: ${filteredSettings.length + 1};`,
      `--omowidget--config--ui--offset--horizontal: ${config.presentation.offset.horizontal};`,
      `--omowidget--config--ui--offset--vertical: ${config.presentation.offset.vertical};`,
      `--omowidget--config--ui--colors-background: ${config.presentation.colors.background};`,
      `--omowidget--config--ui--colors-background--button: ${config.presentation.colors.backgroundButton};`,
      `--omowidget--config--ui--colors-foreground--button: ${config.presentation.colors.foregroundButton};`,
      `--omowidget--config--ui--colors-highlighted--button: ${config.presentation.colors.highlightedButton};`,
      `--omowidget--config--settings--fontFamily-length: ${config.settings.fontFamily.value.values.length + 1};`,
      `--omowidget--config--settings--colors-length: ${config.settings.colors.value.values.length + 1};`,
      `--omowidget--config--ui--colors-power--button: ${config.presentation.colors.powerButton};`,
      `--omowidget--config--ui--buttons-per-row: ${config.presentation.buttonsPerRow};`,
      config.presentation.colors.fillColor ? `--omowidget--config--ui--icon-fill-color: ${config.presentation.colors.fillColor}` : '',
    ];
    return composeCssBlock(
      '',
      ['html.__omowidget__enabled', 'html.__omowidget__enabled ::before', 'html.__omowidget__enabled ::after'],
      ['--OMO-COMMENT: Omoguru CONFIGURATION definition;', ...configVariablesSyntax],
    );
  }

  /**
   * Composes overriding CSS variables
   * @params settings User settings
   */
  private _generateOverriddingCssVariables(settings: WidgetUserSettings) {
    // Get configuration
    const config = ConfigurationService.singleton().config;

    // Set up variables' values
    const variablesCurrentSyntax = actions
      .map(action => {
        if (action.generateVariablesCurrentSyntax(globalEnabledAction) !== undefined) return action.generateVariablesCurrentSyntax(globalEnabledAction);
      })
      .filter(s => !!s) as string[];

    // Default settings application
    const settingsApplicationSyntax = [
      '--omowidget--css--fontfamily--original--value: initial;',
      '--omowidget--css--fontsize--original--value: 1em;',
      '--omowidget--css--lineheight--original--value: 1.2em;',
      '--omowidget--css--linespacing--original--value: 0px;',
      '--omowidget--css--background--original--value: initial;',
      '--omowidget--css--foreground--original--value: initial;',
      ` --omowidget--css--fontweight--original--value: initial;`,
    ];

    // Additions' seelctors
    const additionsSelectors = (config.stylesheets.additions || [])
      .map(addition => {
        return addition.selectors
          .map(selector => {
            const composedSelectors = selector.split(',').map(splitted => {
              return composeCssSelector(splitted);
            });
            return composedSelectors.join(', ');
          })
          .join(', ');
      })
      .join(', ');

    // Compose CSS variables' syntax
    return [
      // Settings' values
      composeCssBlock(
        `Settings, User configured values`,
        [
          'html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__',
          'html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__ ::before',
          'html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__ ::after',
          additionsSelectors ? additionsSelectors : '',
        ],
        ['--OMO-COMMENT: Omoguru USER SETTINGS definition;', ...variablesCurrentSyntax],
      ),

      // Default settings application
      composeCssBlock(
        'Settings applied to bottom level',
        [
          'html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__',
          'html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__ ::before',
          'html.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__ ::after',
        ],
        ['--OMO-COMMENT: Omoguru DEFAULT SETTINGS definition;', ...settingsApplicationSyntax],
      ),
    ].join('\n');
  }
  // #endregion

  // #region CSS syntax: "rem" unit mitigation syntax
  /**
   * Composes "rem" mitigation syntax
   */
  private _generateRemMitigationCssSyntax(): string[] {
    // Get configuration
    const config = ConfigurationService.singleton().config;

    // Check to see if "rem" units used in CSS syntax
    const remCount = config.stylesheets.analysis.reduce((count: number, stylesheet) => {
      return count + (stylesheet.remUsages || []).length;
    }, 0);

    // Compose CSS syntax with "rem" mitigation overrides
    // return (
    //   config.stylesheets.analysis
    //     // Compose all stylesheets
    //     .reduce((overrides: string[], stylesheet) => {
    //       (stylesheet.remUsages || [])
    //         // Compose all rem usage overrides
    //         .reduce((overrides: string[], remUsage) => {
    //           overrides.push(baseAction.overrideRemDeclaration(remUsage));
    //           return overrides;
    //         }, overrides);
    //       return overrides;
    //     }, [])

    // Lock root element font-size in place
    const htmlEl = document.querySelector('html');
    if (htmlEl && remCount && config.stylesheets.lockRemValues) {
      const fontSize = getComputedStyle(htmlEl).fontSize;
      return [`html.__omowidget__enabled.__omowidget__global__enabled { font-size: ${fontSize} !important; }`];
    }
    return [];
  }
  // #endregion
}

/**
 * Composes a selector, pre-pending a html preselector where needed with required class-names attached
 * @param selector Additional CSS selector
 * @param className (Optional) class name to be appended to the html part of the selector
 */
export function composeCssSelector(selector: string, className: string = '.__omowidget__enabled.__omowidget__global__enabled.__omowidget__active__'): string {
  const levels = selector.split(' ');
  // If selector contains "html" even if nested in "html", append existing html
  if (levels.find(level => level.toLowerCase().substr(0, 4) === 'html')) {
    return levels.map(level => (level.toLowerCase().substr(0, 4) === 'html' ? `${level}${className}` : level)).join(' ');
  } else {
    return `html${className} ${levels.join(' ')}`;
  }
}

/**
 * Composes CSS the from separate selectors and declarations
 * @param comment CSS comment to preface the block with
 * @param selectors CSS selectors to use
 * @param declarations CSS declarations to use
 */
export function composeCssBlock(comment: string, selectors: string[], declarations: string[]) {
  return [
    // Comment
    ...(comment ? [`/* ${comment} */`] : []),
    // Selectors
    selectors
      .map(s => s.trim())
      .filter(s => !!s)
      .join(',\n'),
    '{',
    // Declarations
    declarations
      .map(d => d.trim())
      .filter(s => !!s)
      .map(d => `  ${d}`)
      .join('\n'),
    '}',
  ].join('\n');
}
