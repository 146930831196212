import { SettingsConfiguration, WidgetUserSettings } from '../../../../../common/types';
import { ConfigurationService } from '../../ConfigurationService';
import { BaseAction } from '../BaseAction';

/**
 * Global enable/disable action implementation
 */
class GlobalEnabledAction extends BaseAction<boolean> {
  // #region Enabled and Active statuses
  public get enabled(): boolean {
    return ConfigurationService.singleton().config.settings.globalEnable.enable;
  }

  protected get _activeRegardlessOfEnabled(): boolean {
    return this.currentValue === true;
  }
  // #endregion

  // #region Value management (initial/current)
  public _getInitialValue(settings: SettingsConfiguration): boolean {
    return true;
  }

  public _getCurrentValue(settings: WidgetUserSettings): boolean | undefined {
    return settings.globalEnabled.value;
  }
  protected _setCurrentValue(settings: WidgetUserSettings, value: boolean): void {
    settings.globalEnabled.value = value;
  }

  protected _resetValue(settings: WidgetUserSettings) {
    settings.globalEnabled.value = this.initialValue;
  }
  // #endregion

  // #region Reflect state onto root element
  public updateHtmlClassListAndAttributes(widgetClosed: boolean): void {
    if (widgetClosed === false) {
      if (this.active) {
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__global__disabled');
        document.getElementsByTagName('html')[0].classList.add('__omowidget__global__enabled');
        document.getElementsByTagName('html')[0].setAttribute('omowidget-global-disabled', 'false');
      } else {
        document.getElementsByTagName('html')[0].classList.remove('__omowidget__global__enabled');
        document.getElementsByTagName('html')[0].classList.add('__omowidget__global__disabled');
        document.getElementsByTagName('html')[0].setAttribute('omowidget-global-disabled', 'true');
      }
    } else {
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__global__enabled');
      document.getElementsByTagName('html')[0].classList.remove('__omowidget__global__disabled');
      document.getElementsByTagName('html')[0].removeAttribute('omowidget-global-disabled');
    }
  }
  // #endregion
}

// Export
export const globalEnabledAction = new GlobalEnabledAction();
