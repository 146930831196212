// Import dependencies
import { Component, h } from 'preact';
import { WidgetUserSettings } from '../../../common/types';
import { UserSettingsStore } from '../stores';
import { WidgetShellComponent } from './WidgetShellComponent';
import { MessageComponent } from './MessageComponent';
import '../style/index.scss';
import { ConfigurationService, ControllerService } from '../services';

/**
 * Initial widget component
 */
export class Widget extends Component {
  public widgetShellComponentRef: any;
  public subscription: any;
  public state = {
    closed: false,
  };

  /**
   * Subscribe to enabling/disabling widget
   */
  public componentDidMount(): void {
    ControllerService.singleton().initialize(() => {
      this.setState({});
      this.widgetShellComponentRef.hostEl.blur();
    });
    this.subscription = UserSettingsStore.getSingletonInstance<WidgetUserSettings>().subscribe((settings?: WidgetUserSettings) => {
      if (settings && this.state.closed !== settings.closed.value) {
        this.setState({ closed: settings.closed.value });
      }
    });
  }

  public componentWillUnmount(): void {
    this.subscription.unsubscribe();
  }

  public render() {
    {
      /* If widget is enabled, render component, otherwise do not render */
    }
    if (UserSettingsStore.getSingletonInstance<WidgetUserSettings>().data?.closed.value === false) {
      return (
        <WidgetShellComponent
          ref={ref => {
            this.widgetShellComponentRef = ref;
          }}
        />
      );
    } else if (
      UserSettingsStore.getSingletonInstance<WidgetUserSettings>().data?.closed.value === true &&
      ConfigurationService.singleton().config.presentation.close.showMessage
    ) {
      return <MessageComponent active={true} messageContent={ConfigurationService.singleton().config.presentation.close.messageContent} />;
    } else {
      return null;
    }
  }
}
