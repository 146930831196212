import { Component, h } from 'preact';
import messageComponentCssSyntax from '../MessageComponent/style.scss';
import svgIconPerson from '!!raw-loader!../../assets/icons/icon_omoguru_person.svg';
import svgIconMenu from '!!raw-loader!../../assets/icons/icon_menu.svg';
import svgIconOmoguruWidget from '!!raw-loader!../../assets/icons/icon_omoguru_widget.svg';
import svgIconClose from '!!raw-loader!../../assets/img/close.svg';
// Props
type MessageComponentProps = {
  active: boolean;
  messageContent?: string;
};
// State
type MessageComponentState = {
  active: boolean;
};
// Component that shows when widget is closing
export class MessageComponent extends Component<MessageComponentProps, MessageComponentState> {
  public state = {
    active: false,
  };
  // Trigger animation on component mounting
  public componentDidMount() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 0);
  }
  public render() {
    return (
      <div className={`__omowidget__host__message__container ${this.props.active && this.state.active ? 'active' : ''}`}>
        <style>
          /**/
          {messageComponentCssSyntax}
          /**/
        </style>

        <i className="__omowidget__host__widget__message" dangerouslySetInnerHTML={{ __html: svgIconOmoguruWidget }}></i>
        <i
          className="__omowidget__host__close__message"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ active: false });
          }}
          dangerouslySetInnerHTML={{ __html: svgIconClose }}
        ></i>

        <p>Omoguru widget je uklonjen.</p>
        <p>
          {' '}
          Uključi ga ponovo pritiskom na <i class="__omowidget__host__widget__message__person" dangerouslySetInnerHTML={{ __html: svgIconPerson }}></i>{' '}
          <br></br>u glavnom izborniku <i class="_omowidget__host__widget__message__menu" dangerouslySetInnerHTML={{ __html: svgIconMenu }}></i> .
        </p>
      </div>
    );
  }
}
