// Import dependencies
import { h } from 'preact';
import { BaseComponent } from '../BaseComponent';
import { ConfigurationService } from '../../services';
import { MenuItemComponent } from '../MenuItemComponent';
import svgIconReset from '!!raw-loader!../../assets/icons/icon-reset.svg';

/**
 * Property type
 */
export type SubmenuItemProps = {
  id: string;
  title: string;
  mainIcon?: string;
  class?: string;
  colorFill: boolean;
  displayValue?: number;
  displayValueMin?: number;
  displayValueMax?: number;
  parentExpanded: boolean;
  expanded: boolean;
  onClick: () => void;
  disabled?: boolean;
  onReset?: () => void;
  borderReset?: boolean;
};

/**
 * Submenu widget component
 */
export class SubmenuComponent extends BaseComponent<SubmenuItemProps, {}> {
  public resetRef: any = undefined;
  /**
   * Invoke child's onClick function
   * @param index Child index
   */
  public invokeClickOnChild(index: number) {
    const children = (this.props.children as any[]).reduce((children, child) => {
      if (child) {
        children.push(...(child instanceof Array ? child : [child]));
      }
      return children;
    }, [] as any[]);
    ConfigurationService.singleton().config.presentation.position.x === 'right' ? children.unshift(this.resetRef) : children.push(this.resetRef);
    children[index]?.props?.onClick?.();
  }

  public render() {
    const uiConfig = ConfigurationService.singleton().config.presentation;
    const children = (this.props.children as any[])?.length;
    const displayValue =
      (100 * ((this.props.displayValue || 0) - (this.props.displayValueMin || 0))) /
      (this.props.displayValueMax !== undefined && this.props.displayValueMin !== undefined ? this.props.displayValueMax - this.props.displayValueMin : 1);
    return (
      <div
        class={`__omowidget__host__submenu ${children ? '' : 'childless'} ${this.props.class || ''} ${this.props.colorFill ? 'value-set' : 'value-unset'} 
      `}>
        <div class={`__omowidget__host__main_icon ${uiConfig.position.x}`}>
          <MenuItemComponent
            class={`${this.props.id} ${this.props.colorFill ? 'value-set' : 'value-unset'}`}
            colorFill={this.props.colorFill}
            colorBorder={this.props.expanded && !this.props.colorFill}
            disabled={this.props.disabled}
            onClick={() => {
              if (!this.props.disabled) {
                this.props.onClick();
              }
            }}>
            {this.props.mainIcon && (
              <i
                dangerouslySetInnerHTML={{
                  __html: this.props.mainIcon,
                }}
              />
            )}
          </MenuItemComponent>
        </div>
        <div class={`__omowidget__host_title ${children ? '' : 'childless'} ${uiConfig.position.x} ${this.props.id} ${this.props.expanded ? 'expanded' : ''} `}>
          <div class="__omowidget__host_title_value" style={`width: ${displayValue || 0}% !important`}></div>
          <p class={`__omowidget__host_title_caption ${this.props.expanded ? 'expanded' : ''}`}>{this.props.title}</p>
        </div>
        <div
          class={`__omowidget__host__children_container ${children ? '' : 'childless'} ${
            this.props.children && (this.props.children as any[]).length > ConfigurationService.singleton().config.presentation.buttonsPerRow
              ? 'multi-line'
              : ''
          } ${this.props.expanded ? 'expanded' : ''} ${this.props.id} ${uiConfig.position.x} `}
          style={
            this.props.expanded
              ? `width: calc(calc(1 + min(${
                  this.props.children ? (this.props.children as any[]).length : 0
                }, var(--omowidget--config--ui--buttons-per-row))) * var(--omowidget--config--ui--size)) !important`
              : ''
          }>
          {/* Reset button */}
          {
            <div class={`__omowidget__host__reset__button_container ${this.props.expanded ? 'expanded' : ''}`}>
              <MenuItemComponent
                ref={ref => {
                  this.resetRef = ref;
                }}
                colorBorder={this.props.borderReset}
                onClick={() => this.props.onReset?.()}>
                <i
                  dangerouslySetInnerHTML={{
                    __html: svgIconReset,
                  }}
                />
              </MenuItemComponent>
            </div>
          }
          {/* Child buttons */}
          <div
            class="__omowidget__host__children_container_wrapper"
            style={
              this.props.expanded
                ? `width: calc(min(${
                    this.props.children ? (this.props.children as any[]).length : 0
                  }, var(--omowidget--config--ui--buttons-per-row)) * var(--omowidget--config--ui--size)) !important`
                : ''
            }>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
